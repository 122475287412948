import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, first, map } from "rxjs/operators";
import { HttpOptions } from "../domain/http";
import { CoreStateValue } from "../states/core.state";
import { CoreStore } from "../states/core.store";

export interface AuthenticationResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  refresh_token: string;
}
interface TokenBody {
  refresh_token?: string;
}

/**
 * This service is responsible for managing the access and refresh tokens.
 */
@Injectable({
  providedIn: "root",
})
export class TokenService {
  private readonly http = inject(HttpClient);
  test: XMLHttpRequest = new XMLHttpRequest();
  store = inject(CoreStore);

  getAccessToken(): CoreStateValue {
    return this.store.getAccessToken();
  }

  setAccessToken(token: string): void {
    this.store.setAccessToken(token);
  }

  getTokenType(): CoreStateValue {
    return this.store.getTokenType();
  }

  setTokenType(tokenType: string): void {
    this.store.setTokenType(tokenType);
  }

  hasRefreshToken(): boolean {
    // the refresh token is stored as an http-only cookie
    return document.cookie.split(";").some((cookie) => {
      return cookie.trim().startsWith("sessionCookie=");
    });
  }

  removeAllTokenData(): void {
    this.store.removeAccessToken();
    this.store.removeRefreshToken();
    this.store.removeTokenType();
  }

  getNewAccessAndRefreshToken(environmentUrl: string): Observable<string | undefined> {
    const tokenUrl = `${environmentUrl}/token`;
    return this.makeRefreshTokenCall(tokenUrl).pipe(
      map((authenticationResponse: AuthenticationResponse) => {
        this.setAccessToken(authenticationResponse.access_token);
        return authenticationResponse.access_token;
      }),
      first(),
    );
  }

  private makeRefreshTokenCall(tokenUrl: string): Observable<AuthenticationResponse> {
    const body: TokenBody = {};

    const options: HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true, // send the http-only cookie with the current refresh token,
      // and let the response set the new refresh token in the http-only cookie
    };

    return this.http.post<AuthenticationResponse>(tokenUrl, body, options).pipe(first(), catchError(this.handleError));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleError(error: Error): Observable<any> {
    console.error(error);
    return throwError(() => new Error(error.message));
  }
}
