import { Component, computed, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SubmitProposalStateService } from "@app/features/submit-proposal/services/submit-proposal-state.service";
import { StatusMessageComponent } from "@app/shared/components/status-message.component";
import { Constants } from "@core/constants";
import { PlanHelper } from "@core/domain/helpers/plan-helper";
import { IonButton, IonIcon, IonTextarea } from "@ionic/angular/standalone";
import { TranslatePipe } from "@ngx-translate/core";
import {
  GenericDataList,
  GenericDataTableComponent,
} from "@shared/components/generic-data-table/generic-data-table.component";
import { PlanStateService } from "@shared/services/plan-state.service";

@Component({
  selector: "pa-submit-proposal-left-confirm",
  templateUrl: "./submit-proposal-left-confirm.component.html",
  styleUrls: ["./submit-proposal-left-confirm.component.scss"],
  standalone: true,
  imports: [
    GenericDataTableComponent,
    FormsModule,
    StatusMessageComponent,
    TranslatePipe,
    IonTextarea,
    IonButton,
    IonIcon,
  ],
})
export class SubmitProposalLeftConfirmComponent {
  private readonly submitProposalStateService = inject(SubmitProposalStateService);
  private readonly planStateService = inject(PlanStateService);
  private readonly planHelper = inject(PlanHelper);

  protected readonly remarkMaxLength = Constants.REMARK_MAX_LENGTH;
  protected uploadBtnDisabled = false;
  protected selectedFiles: File[] = this.submitProposalStateService.uploadedFilesInfo.map((fileInfo) => fileInfo.file);
  protected approveRemark = this.submitProposalStateService.approveRemark;
  protected electives = this.submitProposalStateService.electives;
  protected warnings = this.submitProposalStateService.warnings;
  protected dataList = computed<GenericDataList[]>(() => {
    // Re-initialize the dataList when the electives change
    const electives = this.electives();
    this.submitProposalStateService.determineSubmitDisabled();
    return electives ? this.submitProposalStateService.buildInfoTable() : [];
  });

  isUploadPossible(): boolean {
    return this.planHelper.isUploadPossible(this.planStateService.currentSelectedPlan()!);
  }

  onFileChange(fileChangeEvent: Event): void {
    const fileInputElement: HTMLInputElement = fileChangeEvent.target as HTMLInputElement;
    const files: FileList | null = fileInputElement.files;
    if (files) {
      this.addToSelectedFiles(Array.from(files));
      this.submitProposalStateService.setUploadedFiles(this.selectedFiles);
    }
  }

  openFile(file: File): void {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  removeFile(index: number): void {
    this.selectedFiles.splice(index, 1);
    this.submitProposalStateService.setUploadedFiles(this.selectedFiles);
  }

  isUploadRequired(): boolean {
    return this.submitProposalStateService.isUploadDocumentRequired();
  }

  maxUploadCountReached(): boolean {
    return this.selectedFiles.length >= this.electives()!.maxDocumentsToSubmit;
  }

  onApproveRemarkChange(): void {
    this.submitProposalStateService.approveRemark = this.approveRemark;
    this.submitProposalStateService.determineSubmitDisabled();
  }

  private addToSelectedFiles(files: File[]) {
    const selectedFiles = [...this.selectedFiles, ...files];

    // Make sure that the selectedFiles array contains only files with unique names
    this.selectedFiles = [...new Map(selectedFiles.map((item) => [item.name, item])).values()];
  }
}
