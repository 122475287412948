export class CaciUtil {
  public static isNullOrEmpty(value: string | undefined | null): boolean {
    return value === undefined || value === null || value.trim().length === 0;
  }

  /**
   * When adding/subtracting numbers with decimals, this can lead to values like 2.2899999999999996 instead of 2.29
   * see https://stackoverflow.com/questions/588004/is-floating-point-math-broken
   * so round it to 3 decimals (the maximum precision for study points).
   **/
  public static roundStudyPoints(valueThatMayHaveDecimals: number): number {
    return Math.round(valueThatMayHaveDecimals * 1000) / 1000;
  }

  /**
   * Convert study points number to a string with the right decimal separator, and with a maximum of 3 decimal places.
   **/
  public static getStudyPointsDisplay(valueThatMayHaveDecimals: number | undefined, languageCode: string): string {
    if (!valueThatMayHaveDecimals) {
      return "";
    }
    const roundedTo3Decimals = CaciUtil.roundStudyPoints(valueThatMayHaveDecimals);
    return roundedTo3Decimals.toLocaleString(languageCode);
  }
}
