import { Component, Input, OnInit, inject } from "@angular/core";
import { Electives } from "@app/core/domain/electives";
import {
  SubmitProposalStateService,
  SubmitProposalStep,
} from "@app/features/submit-proposal/services/submit-proposal-state.service";
import { SubmitProposalLeftComponent } from "../submit-proposal-left/submit-proposal-left.component";
import { SubmitProposalRightComponent } from "../submit-proposal-right/submit-proposal-right.component";

@Component({
  selector: "pa-submit-proposal-content",
  templateUrl: "./submit-proposal-content.component.html",
  styleUrls: ["./submit-proposal-content.component.scss"],
  standalone: true,
  imports: [SubmitProposalLeftComponent, SubmitProposalRightComponent],
})
export class SubmitProposalContentComponent implements OnInit {
  private readonly modalStateService = inject(SubmitProposalStateService);

  @Input() electives!: Electives;
  @Input() step!: SubmitProposalStep;

  public approveRemark = "";
  public submitBtnDisabled: boolean = false;

  ngOnInit(): void {
    if (this.electives) {
      this.modalStateService.initElectives(this.electives, this.step);
    }
  }
}
