@if (electives(); as electives) {
  @if (electives.textConfirmProposal.trim().length !== 0) {
    <div class="ion-padding-bottom">{{ electives.textConfirmProposal }}</div>
  }
  <pa-generic-data-table [list]="dataList()" />

  <ion-textarea
    class="md-block pa-approve-planning-remark ion-padding-top"
    label="{{ 'label.explanation' | translate }} ({{ 'label.mandatory' | translate }})"
    labelPlacement="floating"
    placeholder="{{ 'submitProposal.placeholderApproveRemark' | translate }}"
    [(ngModel)]="approveRemark"
    (ionInput)="onApproveRemarkChange()"
    [maxlength]="remarkMaxLength"
    [counter]="true"
    fill="solid"
    auto-grow="true"
  >
  </ion-textarea>

  <!-- file upload start-->
  @if (isUploadPossible()) {
    <div class="ion-margin-vertical">
      <div class="ion-margin-top">
        <b>{{ 'submitProposal.uploadDocument' | translate }}</b>
        @if (isUploadRequired()) {
          ({{ 'label.mandatory' | translate }})
        }
      </div>
      @if (electives.textConfirmUpload) {
        <div>{{ electives.textConfirmUpload }}</div>
      }
      <ion-button fill="solid" color="secondary" size="small" (click)="fileSelect.click()" class="ion-margin-top">
        {{ 'submitProposal.selectFile' | translate }}
        <input
          #fileSelect
          type="file"
          id="fileSelect"
          name="fileSelect"
          [disabled]="maxUploadCountReached()"
          (change)="onFileChange($event)"
          multiple
        />
      </ion-button>
      @if (isUploadRequired()) {
        <div>{{ 'submitProposal.uploadRequired' | translate }}</div>
      }
    </div>
  }

  @if (selectedFiles.length) {
    <div>
      @for (file of selectedFiles; track $index) {
        <div class="file-row">
          <ion-icon name="attach" color="success" />
          <a href="#" (click)="openFile(file)" class="file-name">{{ file.name }}</a>
          <a #downloadLink class="hidden-anchor"></a>
          <ion-icon name="close-outline" (click)="removeFile($index)" />
        </div>
      }
    </div>
  }

  @if (warnings().length) {
    <hr class="warning-divider" />
    <div>
      @for (warning of warnings(); track $index) {
        <div class="warning">
          <pa-status-message [melding]="warning" />
        </div>
      }
    </div>
  }
}
