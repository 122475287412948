import { Electives, Minor } from "@app/core/domain/electives";
import { SubmitProposalStateService } from "@app/features/submit-proposal/services/submit-proposal-state.service";
import { Course } from "@core/domain/course";
import { TranslateService } from "@ngx-translate/core";
import { CourseDataMapper } from "@shared/services/course-data-mapping.service";
import { CourseData } from "@shared/types/course-data";

export type CourseItem = {
  selected: boolean;
  selectable: boolean;
  disabled: boolean;
  course: Course;
  courseData: CourseData;
  onSelect?: () => void;
};

export type CourseGroup = {
  title: string;
  subTitle: string | undefined;
  items: CourseItem[];
};

export class ChoiceCourseListBuilder {
  constructor(
    private readonly translate: TranslateService,
    private readonly courseDataMapper: CourseDataMapper,
    private readonly submitProposalStateService: SubmitProposalStateService,
  ) {}

  buildCourseList(electives: Electives): CourseGroup[] {
    const individualArrangement = this.extractIndividualArrangement(electives);
    const minors = this.extractMinors(electives);
    const courses = this.extractCourses(electives);

    return [...individualArrangement, ...minors, ...courses];
  }

  private extractIndividualArrangement(electives: Electives): CourseGroup[] {
    const groups: CourseGroup[] = [];

    if (electives?.individualArrangements?.length) {
      const group = this.buildCourseGroup(this.translate.instant("submitProposal.coursesIndividualArrangements"));
      electives.individualArrangements.forEach((course) => {
        this.addCourseToGroup(course, group, { selected: true, selectable: false, disabled: true });
      });

      group.items.length && groups.push(group);
    }

    return groups;
  }

  private extractMinors(electives: Electives): CourseGroup[] {
    const groups: CourseGroup[] = [];

    // TODO: nu even de minorcursussen uitgesloten bij plaatsen via PlanApp, maar
    // bij story om minors mee te nemen bij het plaatsen moeten we hier ook iets mee
    if (!this.submitProposalStateService.planAllowsChangeExamComponent() && electives.minors?.length) {
      electives.minors.forEach((minor) => {
        const minorInProposal = this.submitProposalStateService.minorsInProposal.find(
          (thisMinorInProposal) => thisMinorInProposal.minor === minor.minor,
        );
        if (minorInProposal) {
          // always true
          const warning =
            minorInProposal.totalPoints < minorInProposal.minimumPoints
              ? `(${this.translate.instant("submitProposal.warningNotEnoughCredits")})`
              : undefined;
          const group = this.buildCourseGroup(minor.name, warning);

          this.findAllMinorCourses(minor).forEach((course) => {
            if (this.isInIndividualArrangement(electives, course)) {
              // TODO What is the desired behaviour? According to old code, the course should not be visible but
              //      should count for the minor minimum requirements. For now: do not include course in minor.
              // TopDesk I 2501 0455
            } else {
              this.addCourseToGroup(course, group, { selected: false, selectable: true, disabled: false }, () => {
                this.submitProposalStateService.courseSelected(electives, course, minor.minor);
              });
            }
          });

          group.items.length && groups.push(group);
        }
      });
    }

    return groups;
  }

  private findAllMinorCourses(minor: Minor): Course[] {
    return minor.examComponents.flatMap((comp) => comp.courses);
  }

  private isInIndividualArrangement(electives: Electives, course: Course): boolean {
    return electives.individualArrangements.some((c) => c.id === course.id);
  }

  private extractCourses(electives: Electives): CourseGroup[] {
    const groups: CourseGroup[] = [];
    let coursesInElectives: Course[] = [];

    if (this.submitProposalStateService.planAllowsChangeExamComponent()) {
      // student has already placed courses in Electives exam component
      coursesInElectives = electives.courses.filter((course) =>
        this.submitProposalStateService.isCourseSelected(course, electives),
      );
      // count points of automatically selected courses
      coursesInElectives.forEach((course) => {
        this.submitProposalStateService.countPointsOfAutomaticallySelectedCourse(course);
      });
    } else {
      coursesInElectives = electives.courses;
    }

    if (coursesInElectives.length) {
      const group = this.buildCourseGroup(this.translate.instant("submitProposal.courses"));
      coursesInElectives.forEach((course) => {
        // if planAllowsChangeExamComponent, the course is already placed,
        // so in that case add it in the same way as courses in individual arrangements,
        // otherwise add it as selectable course
        this.addCourseToGroup(
          course,
          group,
          {
            selected: this.submitProposalStateService.planAllowsChangeExamComponent(),
            selectable: !this.submitProposalStateService.planAllowsChangeExamComponent(),
            disabled: this.submitProposalStateService.planAllowsChangeExamComponent(),
          },
          () => {
            this.submitProposalStateService.courseSelected(electives, course);
          },
        );
      });

      group.items.length && groups.push(group);
    }

    return groups;
  }

  private buildCourseGroup(
    header: string,
    subHeader: string | undefined = undefined,
    items: CourseItem[] = [],
  ): CourseGroup {
    return {
      title: header,
      subTitle: subHeader,
      items,
    };
  }

  private addCourseToGroup(
    course: Course,
    group: CourseGroup,
    options: { selected: boolean; selectable: boolean; disabled: boolean } = {
      selected: false,
      selectable: true,
      disabled: false,
    },
    onSelect?: () => void,
  ): void {
    const courseData = this.courseDataMapper.mapCourseData(course);
    group.items.push({
      selected: options.selected,
      selectable: options.selectable,
      disabled: options.disabled,
      course,
      courseData,
      onSelect,
    } as CourseItem);
  }
}
