<div class="course-list-item">
  @if (showCheckbox) {
    <ion-checkbox
      class="list-checkbox ion-focusable"
      [checked]="checked"
      (ionChange)="onToggle()"
      [disabled]="disabled || (!selectable() && !selected())"
    />
  }
  <div class="list-item">
    <ng-content />
  </div>
</div>
