import { inject, Injectable } from "@angular/core";
import { Minor } from "@app/core/domain/electives";
import { ApiCursus, ApiCursussen } from "@core/api/model/api-cursus";
import { ApiExamenonderdelen } from "@core/api/model/api-examenonderdeel";
import {
  ApiPlanLater,
  ApiPlanLaterProfilering,
  ApiSpecialisatie,
  ApiSpecialisatieExamenonderdeel,
  ApiSpecialisatieRuimte,
} from "@core/api/model/api-plan-later";
import { ApiStatusmelding } from "@core/api/model/api-statusmelding";
import { Course } from "@core/domain/course";
import { ExamComponent } from "@core/domain/exam-component";
import { ApiHelper } from "@core/domain/helpers/api-helper";
import { StatusMessageHelper } from "@core/domain/helpers/status-message-helper";
import { PlanLater, PlanLaterElectives, Specialisation, Specialisations } from "@core/domain/plan-later";
import { StatusMessage } from "@core/domain/status-message";
import { CourseMappingService } from "@core/mapping/course-mapping.service";
import { ApiProfielMinor } from "../api/model/api-profilering";
import { ExamComponentMappingService } from "./exam-component-mapping.service";

@Injectable({
  providedIn: "root",
})
export class PlanLaterMappingService {
  private readonly courseMapper = inject(CourseMappingService);
  private readonly examComponentMapper = inject(ExamComponentMappingService);
  private readonly messageHelper = inject(StatusMessageHelper);

  private planLater!: PlanLater;

  mapPlanLater(apiPlanLater: ApiPlanLater): PlanLater {
    this.planLater = {
      id: apiPlanLater.spla_id,
      name: apiPlanLater.planning_naam,
      degreeProgram: apiPlanLater.opleiding,
      yearNr: apiPlanLater.start_jaar,
      yearCount: apiPlanLater.aantal_jaar,
      isDraft: ApiHelper.caciBooleanToBoolean(apiPlanLater.concept_planning),
      statusMessages: this.extractStatusMessages(apiPlanLater.statusmeldingen) ?? [],
      mandatory: this.extractExamComponents(apiPlanLater.verplicht) ?? [],
      mandatoryChoice: this.extractExamComponents(apiPlanLater.verplichte_keuze) ?? [],
      specialisation: this.extractSpecialisations(apiPlanLater.specialisatieruimte) ?? undefined,
      electives: this.extractPlanLaterElectives(apiPlanLater.profilering) ?? [],
      replacements: this.extractReplacements(apiPlanLater.vervangingsregelingen) ?? [],
      otherCourses: this.extractOtherCourses(apiPlanLater.losse_cursussen) ?? [],
    };

    return this.planLater;
  }

  private extractStatusMessages(statusmeldingen?: ApiStatusmelding[]): StatusMessage[] {
    if (statusmeldingen) {
      const messages = statusmeldingen.map((msg) => ApiHelper.toStatusMessage(msg));

      if (messages) {
        return messages.filter((msg) => this.messageHelper.isGenericStatusMessage(msg));
      }
    }

    return [];
  }

  private extractExamComponents(examComponents?: ApiExamenonderdelen): ExamComponent[] {
    const examComponentsResult: ExamComponent[] = [];

    if (examComponents?.examenonderdelen?.length) {
      examComponentsResult.push(
        ...examComponents.examenonderdelen.map((comp) => this.examComponentMapper.mapExamComponent(comp)),
      );
    }

    return examComponentsResult;
  }

  private toCourse(cursus: ApiCursus): Course {
    return this.courseMapper.mapCourse(cursus);
  }

  private extractPlanLaterElectives(apiPlanLaterProfilering: ApiPlanLaterProfilering): PlanLaterElectives {
    return {
      examComponentCode: apiPlanLaterProfilering.examenonderdeel,
      examComponentName: apiPlanLaterProfilering.examenonderdeel_naam,
      label: apiPlanLaterProfilering.label,
      labelColor: apiPlanLaterProfilering.kleur,
      minimumPoints: apiPlanLaterProfilering.minimum_punten,
      conditionsMet: ApiHelper.caciBooleanToBoolean(apiPlanLaterProfilering.voldaan),
      minorRequirementType: apiPlanLaterProfilering.profil_minor_eis_type,
      minorRequirementTypeDescription: apiPlanLaterProfilering.profil_minor_eis_type_oms,
      courses: apiPlanLaterProfilering.cursussen.map((cursus: ApiCursus) => this.toCourse(cursus)),
      minors: apiPlanLaterProfilering.minors.map((minor) => this.toMinorForPlanLater(minor)),
    };
  }

  private toMinorForPlanLater(apiMinor: ApiProfielMinor): Minor {
    return {
      minor: apiMinor.minor,
      name: apiMinor.naam,
      type: apiMinor.type,

      code: apiMinor.code,
      examComponents: apiMinor.examenonderdelen.map((comp) => this.examComponentMapper.mapMinorExamComponent(comp)),
      content: apiMinor.inhoud,
      typeBama: apiMinor.type_bama,
      typePart: apiMinor.type_onderdeel,
      isRegistered: ApiHelper.caciBooleanToBoolean(apiMinor.status_ingeschreven),
      minorStudyProgram: apiMinor.minor_examenprogramma,
      studyProgram: apiMinor.minor_examenprogramma,
      minimumPoints: apiMinor.minimum_punten,
    } as Minor;
  }

  private extractReplacements(apiReplacementCourses?: ApiCursussen): Course[] | undefined {
    return apiReplacementCourses?.cursussen.map((cursus: ApiCursus) => this.toCourse(cursus));
  }

  private extractOtherCourses(apiOtherCourses: ApiCursussen): Course[] | undefined {
    return apiOtherCourses?.cursussen.map((cursus: ApiCursus) => this.toCourse(cursus));
  }

  private extractSpecialisations(apiSpecialisations?: ApiSpecialisatieRuimte | undefined): Specialisations | undefined {
    if (!apiSpecialisations) {
      return undefined;
    }

    return {
      examComponentCode: apiSpecialisations.examenonderdeel,
      examComponentName: apiSpecialisations.examenonderdeel_naam,
      label: apiSpecialisations.label,
      labelColor: apiSpecialisations.kleur,
      minimumPoints: apiSpecialisations.minimum_punten,
      maximumPoints: apiSpecialisations.maximum_punten,
      conditionsMet: ApiHelper.caciBooleanToBoolean(apiSpecialisations.voldaan),
      specialisations: apiSpecialisations.specialisaties?.map(
        (specialisation) => this.extractSpecialisation(specialisation) ?? [],
      ),
    };
  }

  private extractSpecialisation(apiSpecialisation: ApiSpecialisatie): Specialisation {
    return {
      code: apiSpecialisation.specialisatie,
      name: apiSpecialisation.naam,
      studyProgramCode: apiSpecialisation.spec_examenprogramma,
      planningState: apiSpecialisation.status_planning,
      examComponents: apiSpecialisation.examenonderdelen?.map((comp) => this.toSpecialisationExamComponent(comp)) ?? [],
      inPlanLater: true,
    };
  }

  private toSpecialisationExamComponent(examComponent: ApiSpecialisatieExamenonderdeel): ExamComponent {
    return {
      id: examComponent.sple_id,
      examComponentCode: examComponent.spec_examenonderdeel,
      examComponentName: examComponent.spec_onderdeel_naam,
      examComponentExplanation: examComponent.spec_examenonderdeel_toelichting,
      label: examComponent.label,
      labelColor: examComponent.kleur,
      type: examComponent.type,
      typeMandatoryChoice: examComponent.type_vk,
      minimumPoints: examComponent.minimum_punten,
      maximumPoints: examComponent.maximum_punten,
      conditionsMet: ApiHelper.caciBooleanToBoolean(examComponent.voldaan),
      conditionsMetMandatoryOrMandatoryChoice: ApiHelper.caciBooleanToBoolean(examComponent.voldaan_vk),
      courses: examComponent.cursussen?.map((course: ApiCursus) => this.toCourse(course)) ?? [],
    };
  }
}
