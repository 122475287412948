import { NgClass } from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { Course } from "@app/core/domain/course";
import { ExamComponentForPlanCourse } from "@app/core/domain/exam-component";
import { CourseHelper } from "@app/core/domain/helpers/course-helper";
import { PlanHelper } from "@app/core/domain/helpers/plan-helper";
import { PlanStateService } from "@app/shared/services/plan-state.service";
import {
  InputCustomEvent,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import { TranslatePipe } from "@ngx-translate/core";

@Component({
  selector: "pa-change-exam-component-modal",
  templateUrl: "./change-exam-component-modal.component.html",
  styleUrls: ["./change-exam-component-modal.component.scss"],
  standalone: true,
  imports: [
    IonRadio,
    IonRadioGroup,
    TranslatePipe,
    IonHeader,
    IonToolbar,
    IonButton,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonFooter,
    NgClass,
  ],
})
export class ChangeExamComponentModalComponent implements OnInit {
  @Input() course!: Course;
  @Input() examComponentsToChooseFrom!: ExamComponentForPlanCourse[];
  private readonly modalCtrl = inject(ModalController);
  private readonly courseHelper = inject(CourseHelper);
  private readonly planHelper = inject(PlanHelper);
  private readonly planStateService = inject(PlanStateService);
  protected currentExamComponentCode!: string;

  ngOnInit(): void {
    this.currentExamComponentCode = this.course.examComponentCode;
  }

  cancel(): void {
    this.modalCtrl.dismiss(null, "cancel");
  }

  confirm(): void {
    this.modalCtrl.dismiss(this.currentExamComponentCode, "confirm");
  }

  handleChange($event: Event): void {
    const changeEvent = $event as InputCustomEvent;

    if (changeEvent.detail.value) {
      this.currentExamComponentCode = changeEvent.detail.value;
    }
  }

  isExamComponentOther(examComponent: ExamComponentForPlanCourse): boolean {
    return this.planHelper.isExamComponentOther(examComponent);
  }

  isLockedProposalComponent(examComponent: ExamComponentForPlanCourse): boolean {
    const plan = this.planStateService.currentSelectedPlan()!;
    return this.planHelper.isLockedProposalComponent(plan, examComponent);
  }

  getCourseName(): string {
    return this.courseHelper.getCourseName(this.course);
  }
}
