import { inject, Injectable } from "@angular/core";
import { Minor } from "@app/core/domain/electives";
import { Period, PlanDetails, StudyYear } from "@app/core/domain/plan-details";
import {
  ApiCursusRequest,
  ApiExamenonderdeelRequest,
  ApiMinorExamenonderdeelRequest,
  ApiPeriodeRequest,
  ApiPlanRequest as ApiPlanAndPlanLaterRequest,
  ApiPlanLaterLosseCursussenRequest,
  ApiPlanLaterMinorsRequest,
  ApiPlanLaterProfileringRequest,
  ApiSpecialisatieRequest,
  ApiSpecialisatieRuimteRequest,
  ApiStudiejaarRequest,
} from "@core/api/model/api-plan-request";
import { Course } from "@core/domain/course";
import { ExamComponent, MinorExamComponent } from "@core/domain/exam-component";
import { ApiHelper } from "@core/domain/helpers/api-helper";
import { PlanLater, PlanLaterElectives, Specialisation, Specialisations } from "@core/domain/plan-later";
import { Constants } from "../constants";
import { CourseHelper } from "../domain/helpers/course-helper";

@Injectable({
  providedIn: "root",
})
export class PlanRequestMappingService {
  private readonly courseHelper = inject(CourseHelper);

  public buildApiPlanRequest(plan: PlanDetails, planLater: PlanLater): ApiPlanAndPlanLaterRequest {
    const includeExamComponents = plan.allowsChangeExamComponent;
    return {
      spla_id: plan.id,
      examenprogramma: plan.studyProgram,
      tweede_examenprogramma: plan.secondStudyProgram,
      studiejaren: plan.studyYears.map((year) => this.mapToApiStudiejaar(year, includeExamComponents)),
      losse_cursussen: this.mapToApiPlanLaterLosseCursussen(planLater.otherCourses),
      profilering: this.mapToApiPlanLaterProfilering(planLater.electives),
      specialisatieruimte: this.mapToApiSpecialisatieRuimte(planLater.specialisation),
    };
  }

  private mapToApiStudiejaar(year: StudyYear, includeExamComponents: boolean): ApiStudiejaarRequest {
    return {
      studiejaar: year.yearNr,
      periodes: year.periods.map((period) => this.mapToApiPeriode(period, includeExamComponents)),
    };
  }

  private mapToApiPeriode(period: Period, includeExamComponents: boolean): ApiPeriodeRequest {
    return {
      periode_nr: period.periodNr,
      periode_oms: period.periodDescription,
      cursussen:
        period.courses
          ?.filter((course) => course.ribbonNr <= 1)
          .map((course) => this.mapToApiCursus(course, includeExamComponents)) ?? [],
    };
  }

  private filterPlanLaterCourses(courses: Course[]): Course[] {
    if (!courses) {
      return [];
    }
    return courses.filter(
      (course) =>
        // Custom courses and courses at another institution cannot actively be moved to plan later.
        // If the plan allows changing exam components, they might show in Plan Later,
        // but we don't want to send them as part of the Plan Later when saving the plan,
        // because they should only appear in Plan Later based on their presence in the plan grid.
        // For example, when a custom course is deleted from the plan grid, we don't want to keep it in Plan Later.
        course.ribbonNr <= 1 && !this.courseHelper.isCustomCourse(course) && !course.isCourseAtAnotherInstitution,
    );
  }

  private mapToApiPlanLaterLosseCursussen(otherCoursesPlanLater: Course[]): ApiPlanLaterLosseCursussenRequest {
    return {
      cursussen: this.filterPlanLaterCourses(otherCoursesPlanLater).map((course) => this.mapToApiCursus(course)) ?? [],
    };
  }

  private mapToApiCursus(course: Course, includeExamComponents = false): ApiCursusRequest {
    const apiCursusRequest: ApiCursusRequest = {
      cursus: course.code,
      korte_naam: course.name,
      eigen_cursus: course.customCourseName,
      eigen_cursus_punten: course.customCoursePoints ?? null,
      lint_periodes: ApiHelper.numberArrayToString(course.ribbonPeriods),
      plan_later: ApiHelper.booleanToCaciBoolean(course.isInPlanLater),
      spcu_id: course.id,
    };
    if (includeExamComponents) {
      if (!course.examComponentCode || course.examComponentCode === "") {
        // if we don't have a valid exam component code, place it in the 'other' component
        apiCursusRequest.examenonderdeel = Constants.EXAM_COMPONENT_OTHER;
      } else {
        apiCursusRequest.examenonderdeel = course.examComponentCode;
      }
      if (apiCursusRequest.examenonderdeel !== Constants.EXAM_COMPONENT_OTHER) {
        // for actual exam components we also want to pass the study program to which it belongs,
        // because the plan may contain two study programs
        apiCursusRequest.examenprogramma = course.studyProgram;
      }
    }
    return apiCursusRequest;
  }

  private mapToApiPlanLaterProfilering(planLaterElectives: PlanLaterElectives): ApiPlanLaterProfileringRequest {
    return {
      minors: planLaterElectives.minors.map((minor) => this.mapToApiPlanLaterMinors(minor)),
      cursussen:
        this.filterPlanLaterCourses(planLaterElectives.courses).map((course) => this.mapToApiCursus(course)) ?? [],
    };
  }

  private mapToApiPlanLaterMinors(minor: Minor): ApiPlanLaterMinorsRequest {
    return {
      examenonderdelen: minor.examComponents.map((examComponent) => this.mapToApiMinorExamenonderdeel(examComponent)),
      minor: minor.minor,
      minor_examenprogramma: minor.studyProgram,
    };
  }

  private mapToApiMinorExamenonderdeel(examComponent: MinorExamComponent): ApiMinorExamenonderdeelRequest {
    return {
      sple_id: examComponent.id,
      examenonderdeel: examComponent.examComponentCode,
      minor_examenonderdeel: examComponent.minorExamComponentCode,
    };
  }

  private mapToApiSpecialisatieRuimte(specialisations: Specialisations | undefined): ApiSpecialisatieRuimteRequest {
    return {
      specialisaties:
        specialisations?.specialisations.map((specialisation) => this.mapToApiSpecialisatie(specialisation)) ?? [],
    };
  }

  private mapToApiSpecialisatie(specialisation: Specialisation): ApiSpecialisatieRequest {
    return {
      examenonderdelen: specialisation.examComponents.map((examComponent) =>
        this.mapToApiExamenonderdeel(examComponent),
      ),
      specialisatie: specialisation.code,
      spec_examenprogramma: specialisation.studyProgramCode,
    };
  }

  private mapToApiExamenonderdeel(examComponent: ExamComponent): ApiExamenonderdeelRequest {
    return {
      sple_id: examComponent.id,
      examenonderdeel: examComponent.examComponentCode,
      spec_examenonderdeel: examComponent.examComponentCode,
    };
  }
}
