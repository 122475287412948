@if (planAllowsChangeExamComponent) {
  <span>{{ 'submitProposal.selectedText' | translate }}</span>
} @else {
  <span>{{ 'submitProposal.selectionText' | translate }}</span>
}

@for (group of courseList(); track $index) {
  <pa-add-course-list-header [title]="group.title" [subTitle]="group.subTitle" />

  @for (courseItem of group.items; track $index) {
    <pa-add-course-list-item
      [showCheckbox]="!planAllowsChangeExamComponent"
      [selected]="courseItem.selected"
      [selectable]="isCourseSelectable(courseItem)"
      [disabled]="courseItem.disabled"
      (selectCourse)="onSelectCourse(courseItem, $event)"
    >
      <pa-course-item [courseData]="courseItem.courseData" showCategory hideShowInfo hideTimeslots />
    </pa-add-course-list-item>
  }
}
