import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Electives, Minor, UploadedFileInfo } from "@app/core/domain/electives";
import { Document, PlanDetails, PlanDetailsResponse } from "@app/core/domain/plan-details";
import { ApiPlanDetail, ApiStatusResponse } from "@core/api/model/api-plan";
import { ApiMappingService } from "@core/mapping/api-mapping.service";
import { GenericHttpService } from "@core/services/generic-http.service";
import { forkJoin, map, Observable, of } from "rxjs";
import { ApiPlanProfilering } from "../api/model/api-profilering";
import { ElectivesMappingService } from "../mapping/electives-mapping.service";

@Injectable({
  providedIn: "root",
})
export class ElectivesService extends GenericHttpService {
  private readonly mappingService = inject(ApiMappingService);
  private readonly electivesMapper = inject(ElectivesMappingService);

  fetchElectives(planId: number): Observable<Electives | undefined> {
    const params = new HttpParams().set("spla_id", planId.toString());
    return this.httpGet<ApiPlanProfilering>("plan/profilering", params).pipe(
      map((planProfilering) => {
        return planProfilering ? this.mappingService.toElectives(planProfilering) : undefined;
      }),
    );
  }

  submitProposal(
    electives: Electives,
    uploadedFiles: UploadedFileInfo[],
    explanation: string,
  ): Observable<PlanDetailsResponse> {
    const proposal = this.electivesMapper.mapToApiProposalRequest(electives, uploadedFiles, explanation);
    const url = `plan/${electives.planId}/indienen_plan_goedkeuring`;

    return this.httpPut<ApiPlanDetail | ApiStatusResponse>(url, undefined, JSON.stringify(proposal)).pipe(
      map((reponse) => this.mappingService.toPlanResponse(reponse)),
    );
  }

  uploadDocuments(planDetails: PlanDetails, uploadedFiles: UploadedFileInfo[]): Observable<void> {
    if (planDetails.documents?.length) {
      this.addDocIdsToUploadedFiles(uploadedFiles, planDetails.documents);
      const uploads = uploadedFiles.map((file) => this.uploadDocument(planDetails.id, file, planDetails.studentNr));

      return forkJoin(uploads).pipe(
        map(() => {
          // console.log('All documents uploaded')
        }),
      );
    }

    // No documents to be uploaded
    return of(void 0);
  }

  private uploadDocument(planId: number, fileInfo: UploadedFileInfo, studentNr: string): Observable<void> {
    const url = `plan/${planId}/indienen_plan_document/${fileInfo.documentId}/document`;

    return this.httpPut(url, undefined, fileInfo.file, this.getStudentNrHeader(studentNr));
  }

  private addDocIdsToUploadedFiles(uploadedFiles: UploadedFileInfo[], documents: Document[]): void {
    documents.forEach((document) => {
      const uploadedFile = uploadedFiles.find(
        (fileInfo) =>
          fileInfo.file.name === document.documentName &&
          fileInfo.file.size === document.documentSize &&
          fileInfo.documentId === undefined,
      );
      if (uploadedFile) {
        uploadedFile.documentId = document.documentId;
      }
    });
  }

  minorEquals(minor1: Minor, minor2: Minor): boolean {
    return minor1.minor === minor2.minor && minor1.name === minor2.name && minor1.type === minor2.type;
  }
}
