import { Course } from "@core/domain/course";

export type Courses = {
  courses: Course[];
};

export enum ExamComponentType {
  MANDATORY = "V", // Verplicht
  MANDATORY_CHOICE = "VK", // Verplichte keuze
  CHOICE = "K", // Keuze
  ELECTIVES = "P", // Profileringsruimte
  SPECIALISATION = "S", // Specialisatie
}

export enum ExamComponentMandatoryChoiceType {
  LIST = "L", // Lijst
  CONDITION = "V", // Vrij veld conditie
  NOT_APPLICABLE = "",
}

export type ExamComponent = {
  id: number;
  label: string;
  labelColor: string;
  type?: ExamComponentType;
  typeMandatoryChoice?: ExamComponentMandatoryChoiceType;
  minimumPoints?: number;
  maximumPoints?: number;
  conditionsMet: boolean;
  conditionsMetMandatoryOrMandatoryChoice: boolean;
  studyProgram?: string;
  examComponentCode: string;
  examComponentName: string;
  examComponentExplanation?: string;
  conditions?: ExamComponentCondition[];
} & Courses;

export type MinorExamComponent = ExamComponent & {
  minorExamComponentCode: string;
  minorExamComponentName: string;
} & Courses;

export type ExamComponentForPlanCourse = {
  studyProgram: string;
  examComponentCode: string;
  examComponentName: string;
  type?: ExamComponentType;
};

export type ExamComponentCondition = {
  section: string;
  sectionDescription: string;
  sectionNumber: string;
  freeField: string;
  freeFieldDescription: string;
  content: string;
};
