import { inject, Injectable, signal } from "@angular/core";
import { CategoryRequirement, Electives, Minor, UploadedFileInfo } from "@app/core/domain/electives";
import { ProposalDocumentUpload } from "@app/core/domain/proposal-status";
import { ElectivesService } from "@app/core/services/electives.service";
import { CaciUtil } from "@app/core/util/caci-util";
import { GenericDataList } from "@app/shared/components/generic-data-table/generic-data-table.component";
import { LanguageService } from "@app/shared/services/language.service";
import { Course } from "@core/domain/course";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { PlanDetails } from "@core/domain/plan-details";
import { StatusMessage, StatusMessageType } from "@core/domain/status-message";
import { ChoiceCourseListBuilder, CourseGroup } from "@feature/submit-proposal/services/choice-course-list.builder";
import { TranslateService } from "@ngx-translate/core";
import { CourseDataMapper } from "@shared/services/course-data-mapping.service";
import { LoadingService } from "@shared/services/loading-service";
import { PlanStateService } from "@shared/services/plan-state.service";
import { map, Observable, of, switchMap, tap } from "rxjs";

export enum SubmitProposalStep {
  Choice = "choice",
  Confirm = "confirm",
}

@Injectable({
  providedIn: "root",
})
export class SubmitProposalStateService {
  // minorsInProposal is used to keep track of selected courses in the electives minors, grouped by minor
  minorsInProposal: MinorInProposal[] = [];
  // coursesByCategory is used to keep track of other selected courses, grouped by category
  // (public for unit tests)
  coursesByCategory: Category[] = [];

  courseGroups: CourseGroup[] = [];

  electives = signal<Electives | undefined>(undefined);
  planAllowsChangeExamComponent = signal(false);
  warnings = signal([] as StatusMessage[]);
  approveRemark: string | undefined;
  uploadedFilesInfo: UploadedFileInfo[] = [];
  submitDisabled = true;

  // totalPoints is public for unit tests
  readonly totalPoints = signal(0);
  // selectedPoints is public for unit tests
  readonly selectedPoints = signal(0);
  // fromLessThanMinToMaxOrMoreWith1Course is true when the user had less than the minimum points selected,
  // then selected one course and reached or exceeded the maximum points
  // (public for unit tests)
  fromLessThanMinToMaxOrMoreWith1Course = false;
  // maxPointsReached is true when the user has selected enough courses to reach the maximum points,
  // or if there is no maximum defined, to reach the minimum points
  private readonly maxPointsReached = signal(false);
  // maxPointsExceeded is true when the user has selected more courses than the maximum points,
  // or if there is no maximum defined, than the minimum points
  private readonly maxPointsExceeded = signal(false);
  private step = SubmitProposalStep.Choice;

  private readonly courseDataMapper = inject(CourseDataMapper);
  private readonly courseHelper = inject(CourseHelper);
  private readonly loadingService = inject(LoadingService);
  private readonly planStateService = inject(PlanStateService);
  private readonly translate = inject(TranslateService);
  private readonly electivesService = inject(ElectivesService);
  private readonly languageService = inject(LanguageService);
  private readonly choiceCourseListBuilder = new ChoiceCourseListBuilder(this.translate, this.courseDataMapper, this);

  initElectives(electives: Electives, step: SubmitProposalStep): void {
    this.resetState();
    this.step = step;

    if (electives.categoryRequirements.length) {
      electives.categoryRequirements.forEach((categoryRequirement) => {
        this.addCategory(categoryRequirement.categoryCode);
      });
      this.addCategory("");
    } else {
      // Create an array of categories from categories of courses listed on the right side
      this.findAllCategories(electives);
    }

    // total points in program = total planned points - points in electives
    this.totalPoints.set(CaciUtil.roundStudyPoints(electives.pointsPlanned - this.calculateElectivePoints(electives)));

    this.setMinorsInProposal(electives);

    electives.individualArrangements.forEach((course: Course) => this.countPointsOfAutomaticallySelectedCourse(course));

    this.planAllowsChangeExamComponent.set(this.planStateService.currentSelectedPlan()!.allowsChangeExamComponent);
    // build the course groups after setting the planAllowsChangeExamComponent
    // because the course groups are built differently based on these values,
    // and build the course groups before buildWarnings,
    // because if the plan allows changing the exam component, buildCourseList ensures the selected points are counted
    this.courseGroups = this.choiceCourseListBuilder.buildCourseList(electives);

    // build the warnings after counting the points of the individual arrangements,
    // and after building the course groups (which counts the points of the courses already placed in the electives)
    this.buildWarnings(electives);

    // set the electives signal last, because it can trigger effects
    this.electives.set(electives);
  }

  changeStepTo(step: SubmitProposalStep): void {
    this.step = step;
    this.warnings.set([]);
    this.electives() && this.buildWarnings(this.electives()!);
  }

  findAllCategories(electives: Electives): void {
    electives.courses.forEach((course: Course) => {
      if (course.categoryCode !== undefined) {
        // W 2501 003 - empty string is a valid category, those courses must also be counted
        this.addCategory(course.categoryCode);
      }
    });

    electives.minors.forEach((minor) => {
      minor.examComponents.forEach((comp) => {
        comp.courses.forEach((course) => {
          if (course.categoryCode) {
            this.addCategory(course.categoryCode);
          }
        });
      });
    });
  }

  isMinimumPointsReached(): boolean {
    return this.selectedPoints() >= this.electives()!.minimumPoints;
  }

  // Calculate points of all courses that we can select in the electives space,
  // but that fall within the current plan of the student (if statement within courses loop)
  private calculateElectivePoints(electives: Electives): number {
    const pointsPerCourse = new Map<string, number>();
    electives.courses.forEach((course: Course) => {
      if (course.statusStudyYear > 0 || course.statusStudyYear === null) {
        pointsPerCourse.set(this.courseHelper.getCourseId(course), course.studyPoints);
      }
    });

    electives.minors
      .flatMap((minor) => minor.examComponents)
      .flatMap((comp) => comp.courses)
      .filter((course) => course.statusStudyYear > 0 || course.statusStudyYear === null)
      .forEach((course) => {
        pointsPerCourse.set(this.courseHelper.getCourseId(course), course.studyPoints);
      });
    const totalElectivePoints = Array.from(pointsPerCourse.values()).reduce(
      (total, coursePoints) => total + coursePoints,
      0,
    );
    // when working with decimals, this can lead to values like 2.2899999999999996
    // even though only values with 2 decimals were used
    // so round it to 3 decimals (the maximum precision for study points).
    return CaciUtil.roundStudyPoints(totalElectivePoints);
  }

  public countPointsOfAutomaticallySelectedCourse(course: Course): void {
    const points: number = this.courseHelper.getCoursePoints(course);

    // Update total points to inform other components
    this.totalPoints.set(CaciUtil.roundStudyPoints(this.totalPoints() + points));
    this.selectedPoints.set(CaciUtil.roundStudyPoints(this.selectedPoints() + points));
    // Update the category points
    this.coursesByCategory.find((category) => category.code === course.categoryCode)?.addCourse(course);
  }

  public isCourseSelectable(course: Course): boolean {
    // User can select courses as long as the maximum number of points is not reached (or exceeded).
    // If there is no maximum, the minimum counts as maximum.
    // The course with which the maximum is exceeded is the last one allowed to be selected,
    // except for courses with 0 points (they can still be selected when the maximum is reached).
    return !this.maxPointsReached() || this.courseHelper.getCoursePoints(course) === 0;
  }

  public courseSelected(electives: Electives, course: Course, minor?: string): void {
    let pointsTotal = this.totalPoints();

    if (minor) {
      const minorInProposal = this.minorsInProposal.find((pm) => pm.minor === minor);
      if (minorInProposal) {
        if (this.findCourse(minorInProposal.courses, course.id)) {
          // course is present in minor
          minorInProposal.removeCourse(course);
        }
        // course not present in minor
        else if (this.isCourseSelectable(course)) {
          minorInProposal.addCourse(course);
        }
      }
    }

    let selectedPoints = this.selectedPoints();
    this.coursesByCategory
      .filter((category) => category.code === course.categoryCode)
      .forEach((category) => {
        const points: number = this.courseHelper.getCoursePoints(course);
        if (this.findCourse(category.courses, course.id)) {
          // course is present in category list, was selected and is now deselected
          category.removeCourse(course);
          const selectedPointsAfterCourseRemoval = CaciUtil.roundStudyPoints(selectedPoints - points);

          // if fromLessThanMinToMaxOrMoreWith1Course was true,
          // check if this course brings the selected points back down below the max
          if (
            this.fromLessThanMinToMaxOrMoreWith1Course &&
            selectedPointsAfterCourseRemoval < this.getMaximumToCompareWith(electives)
          ) {
            this.fromLessThanMinToMaxOrMoreWith1Course = false;
          }

          selectedPoints = selectedPointsAfterCourseRemoval;
          pointsTotal -= points;
        } else if (this.isCourseSelectable(course)) {
          // course not present in category list, was not selected yet and is now selected
          category.addCourse(course);
          const selectedPointsAfterCourseAddition = CaciUtil.roundStudyPoints(selectedPoints + points);

          // check if this course brings the selected points from less than min to max or more
          if (
            selectedPoints < electives.minimumPoints &&
            selectedPointsAfterCourseAddition >= this.getMaximumToCompareWith(electives)
          ) {
            this.fromLessThanMinToMaxOrMoreWith1Course = true;
          }

          selectedPoints = selectedPointsAfterCourseAddition;
          pointsTotal += points;
        }
      });

    // Update total points to inform other components
    this.totalPoints.set(CaciUtil.roundStudyPoints(pointsTotal));
    this.selectedPoints.set(CaciUtil.roundStudyPoints(selectedPoints));
    this.buildWarnings(electives);
  }

  private findCourse(courses: Course[], id?: number): Course | undefined {
    return courses.find((course) => course.id === id);
  }

  private addCategory(categoryCode: string): void {
    if (!this.coursesByCategory.some((category) => category.code === categoryCode)) {
      this.coursesByCategory.push(new Category(categoryCode));
    }
  }

  private getMaximumToCompareWith(electives: Electives): number {
    return electives.maximumPoints ?? electives.minimumPoints;
  }

  private calculateMaxPointsExceeded(electives: Electives) {
    this.maxPointsExceeded.set(this.selectedPoints() > this.getMaximumToCompareWith(electives));
  }

  private calculateMaxPointsReached(electives: Electives) {
    this.maxPointsReached.set(this.selectedPoints() >= this.getMaximumToCompareWith(electives));
  }

  setUploadedFiles(uploadedFiles: File[]): void {
    this.uploadedFilesInfo = uploadedFiles.map(
      (uploadedFile) =>
        ({
          file: uploadedFile,
        }) as UploadedFileInfo,
    );
    this.determineSubmitDisabled();
  }

  // public for unit tests
  buildWarnings(electives: Electives): void {
    this.calculateMaxPointsReached(electives);
    this.calculateMaxPointsExceeded(electives);

    this.warnings.set(
      this.step === SubmitProposalStep.Choice ? this.buildWarningsChoice(electives) : this.buildWarningsConfirm(),
    );
  }

  private buildWarningsChoice(electives: Electives): StatusMessage[] {
    const warnings: StatusMessage[] = [];

    const hasEnoughPoints = this.selectedPoints() >= electives.minimumPoints;
    if (electives.minorRequirementType && hasEnoughPoints) {
      const minorNotSelected = this.minorsInProposal.some(
        (minorInProposal) =>
          minorInProposal.type === electives.minorRequirementType &&
          minorInProposal.totalPoints < minorInProposal.minimumPoints,
      );
      if (minorNotSelected) {
        const msg = this.translate.instant("submitProposal.warningMinorRequirement", {
          name: electives.minorRequirementTypeDescription,
        });
        warnings.push(this.buildWarning(msg));
      }
    }

    if (this.planStateService.hasValidationMessages()) {
      warnings.push(this.buildWarning(this.translate.instant("submitProposal.warningValidation")));
    }

    if (this.fromLessThanMinToMaxOrMoreWith1Course || (this.maxPointsReached() && !this.maxPointsExceeded())) {
      const msg = this.translate.instant("submitProposal.warningAllowedCreditsReached", {
        name: electives.examComponentName,
      });
      warnings.push(this.buildWarning(msg));
    }

    if (!this.fromLessThanMinToMaxOrMoreWith1Course && this.maxPointsExceeded()) {
      const msg = this.translate.instant("submitProposal.warningAllowedCreditsExceeded", {
        name: electives.examComponentName,
      });
      warnings.push(this.buildWarning(msg));
    }

    return warnings;
  }

  private buildWarningsConfirm(): StatusMessage[] {
    const warnings: StatusMessage[] = [];

    warnings.push(...this.buildWarningsMinorCoursesChecked(this.minorsInProposal));

    return warnings;
  }

  private buildWarningsMinorCoursesChecked(minors: MinorInProposal[]) {
    const warnings: StatusMessage[] = [];

    minors.forEach((minorInProposal) => {
      if (minorInProposal.totalPoints > 0 && minorInProposal.totalPoints < minorInProposal.minimumPoints) {
        const msg = this.translate.instant("submitProposal.warningMinorNotAllCoursesSelected", {
          minorName: minorInProposal.name,
          electivesName: this.electives()?.examComponentName ?? "",
        });
        warnings.push(this.buildWarning(msg));
      }
    });

    return warnings;
  }

  private buildWarning(message: string): StatusMessage {
    return {
      message,
      code: 0,
      type: StatusMessageType.WARNING,
    };
  }

  isUploadDocumentRequired(): boolean {
    return this.planStateService.currentSelectedPlan()!.proposalDocumentToUpload === ProposalDocumentUpload.REQUIRED;
  }

  determineSubmitDisabled(): void {
    // remark is always required
    const hasRemark: boolean = !!this.approveRemark?.length;
    // sometimes documents are also required
    const requiredFilesUploaded = this.isUploadDocumentRequired() ? !!this.uploadedFilesInfo.length : true;

    this.submitDisabled = !(hasRemark && requiredFilesUploaded);
  }

  private getCategoryTotalPoints(categoryRequirement: CategoryRequirement): number {
    return (
      this.coursesByCategory.find((category) => category.code === categoryRequirement.categoryCode)?.totalPoints ?? 0
    );
  }

  submitProposal(): Observable<boolean> {
    if (this.electives()) {
      // Update the electives: only selected minor/other courses
      const updatedElectives = this.getElectivesToSubmit();

      return this.loadingService.present("submitProposal.submittingPlan").pipe(
        switchMap(() =>
          this.electivesService.submitProposal(updatedElectives, this.uploadedFilesInfo, this.approveRemark ?? ""),
        ),
        switchMap((planResponse) => {
          if (planResponse) {
            if (planResponse.statusMessages?.length) {
              const warnings = planResponse.statusMessages.map((msg) => this.buildWarning(msg.message));
              this.warnings.set(warnings);
              this.loadingService.dismiss();
              return of(false); // Keep the modal open
            } else {
              // If plan has documents to upload, upload them now
              return this.electivesService.uploadDocuments(planResponse as PlanDetails, this.uploadedFilesInfo).pipe(
                tap(() => this.loadingService.dismiss()),
                tap(() => this.resetState()),
                map(() => true), // Close modal
              );
            }
          }

          return of(false);
        }),
      );
    }

    return of(false);
  }

  /** Set the minors and courses of the electives to only the list of selected ones. */
  private getElectivesToSubmit(): Electives {
    const electivesToSubmit: Electives = {
      ...this.electives()!,
      minors: [],
      courses: [],
    };

    // add all courses from minors that are selected
    this.minorsInProposal.forEach((minorInProposal) => {
      if (minorInProposal.totalPoints >= minorInProposal.minimumPoints) {
        const updatedMinor = this.findMinorAndLimitToSelectedCourses(minorInProposal);
        updatedMinor && electivesToSubmit.minors.push(updatedMinor);
      } else {
        // Minor requirements are not met, add (selected) courses as individual (other) courses.
        // Note that minorInProposal.courses are already filtered to only include selected courses.
        electivesToSubmit.courses.push(...minorInProposal.courses);
      }
    });

    // add all other courses that are selected
    const selectedOtherCourses: Course[] = this.electives()!.courses.filter((course: Course) =>
      this.isCourseSelected(course, this.electives()!),
    );
    selectedOtherCourses && electivesToSubmit.courses.push(...selectedOtherCourses);

    return electivesToSubmit;
  }

  private findMinorAndLimitToSelectedCourses(minorInProposal: MinorInProposal): Minor | undefined {
    const minor = this.electives()?.minors.find((m) => minorInProposal.isEqual(m));

    if (minor !== undefined) {
      // Should never be undefined
      minor.examComponents.forEach((component) => {
        component.courses = component.courses.filter((course) => this.isSelectedMinorCourse(course, minorInProposal));
      });
    }

    return minor;
  }

  private isSelectedMinorCourse(course: Course, minorInProposal: MinorInProposal) {
    return minorInProposal.courses.some((c) => c.id === course.id);
  }

  isCourseSelected(course: Course, electives: Electives): boolean {
    if (this.planAllowsChangeExamComponent()) {
      return course.examComponentCode === electives.examComponentCode;
    }
    return this.coursesByCategory.some((category) => category.courses.some((c) => c.id === course.id));
  }

  buildInfoTable(): GenericDataList[] {
    const electives = this.electives()!;
    const list: GenericDataList[] = [];
    const languageCode = this.languageService.getLanguage();
    if (electives.minimumPoints > 0) {
      list.push({
        label: `${this.translate.instant("submitProposal.amountRequiredEcts")}:`,
        value: CaciUtil.getStudyPointsDisplay(electives.minimumPoints, languageCode),
      });
    }
    if (electives.maximumPoints && electives.maximumPoints > 0) {
      list.push({
        label: `${this.translate.instant("submitProposal.amountMaxEcts")}:`,
        value: CaciUtil.getStudyPointsDisplay(electives.maximumPoints, languageCode),
      });
    }
    list.push({
      label: `${this.translate.instant("submitProposal.amountSelectedEcts")}:`,
      value: CaciUtil.getStudyPointsDisplay(this.selectedPoints() ?? 0, languageCode),
      whitespace: true,
    } as GenericDataList);
    list.push({
      label: `${this.translate.instant("submitProposal.totaalGepland")}:`,
      value: CaciUtil.getStudyPointsDisplay(this.totalPoints() ?? 0, languageCode),
    } as GenericDataList);

    if (electives.categoryRequirements.length) {
      let whitespace = true;
      for (const category of electives.categoryRequirements) {
        list.push({
          label: `${category.categoryDescription}:`,
          value: `${CaciUtil.getStudyPointsDisplay(this.getCategoryTotalPoints(category), languageCode)}`,
          whitespace,
        });
        whitespace = false;
      }
    }

    return list;
  }

  setMinorsInProposal(electives: Electives): void {
    this.minorsInProposal = electives.minors.map(
      (minor) => new MinorInProposal(minor.minor, minor.name, minor.minorStudyProgram, minor.minimumPoints, minor.type),
    );
  }

  private resetState() {
    this.electives.set(undefined);
    this.coursesByCategory = [];
    this.minorsInProposal = [];
    this.warnings.set([]);
    this.selectedPoints.set(0);
    this.totalPoints.set(0);
    this.fromLessThanMinToMaxOrMoreWith1Course = false;
    this.uploadedFilesInfo = [];
    this.courseGroups = [];
    this.planAllowsChangeExamComponent.set(false);
    this.approveRemark = undefined;
    this.submitDisabled = true;
  }
}
export class CourseListWithTotalPoints {
  courses: Course[] = [];
  totalPoints: number = 0;

  addCourse(course: Course): void {
    let additionalPoints = 0;
    const found = this.courses.some((thisCourse: Course) => thisCourse.id === course.id);

    if (!found) {
      this.courses.push(course);
      additionalPoints = course.studyPoints;
    }

    this.totalPoints = CaciUtil.roundStudyPoints(this.totalPoints + additionalPoints);
  }

  removeCourse(course: Course): void {
    let lossOfPoints = 0;
    const index = this.courses.findIndex((c) => c.id === course.id);
    if (index !== -1) {
      this.courses.splice(index, 1);
      lossOfPoints = course.studyPoints;
    }

    this.totalPoints = CaciUtil.roundStudyPoints(this.totalPoints - lossOfPoints);
  }
}

export class Category extends CourseListWithTotalPoints {
  code: string;

  constructor(category: string) {
    super();
    this.code = category;
  }
}

export class MinorInProposal extends CourseListWithTotalPoints {
  minor: string;
  name: string;
  type: string;
  studyProgram: string;
  minimumPoints: number;

  constructor(minor: string, name: string, studyProgram: string, minimumPoints: number, _type: string) {
    super();
    this.minor = minor;
    this.name = name;
    this.studyProgram = studyProgram;
    this.minimumPoints = minimumPoints;
    this.type = _type;
  }

  isEqual(minor: Minor): boolean {
    return minor.minor === this.minor && minor.name === this.name && minor.type === this.type;
  }
}
