import { Component, inject, Input, OnInit } from "@angular/core";
import { CaciUtil } from "@app/core/util/caci-util";
import { LanguageService } from "@app/shared/services/language.service";
import { TranslateService, Translation } from "@ngx-translate/core";

@Component({
  selector: "pa-study-points",
  templateUrl: "./study-points.component.html",
  styleUrls: ["./study-points.component.scss"],
  standalone: true,
})
export class StudyPointsComponent implements OnInit {
  @Input() points!: number | undefined;

  private readonly translateService = inject(TranslateService);
  private readonly languageService = inject(LanguageService);
  protected pointsAriaLabel: Translation = "";
  protected pointsDisplay: string = "";

  ngOnInit(): void {
    this.pointsDisplay = CaciUtil.getStudyPointsDisplay(this.points, this.languageService.getLanguage());
    this.pointsAriaLabel = `${this.translateService.instant("courseDetail.studyPoints")} ${this.pointsDisplay}`;
  }
}
