<ng-container>
  @switch (step) {
    @case (SubmitProposalStep.Choice) {
      <pa-submit-proposal-left-choice />
    }
    @case (SubmitProposalStep.Confirm) {
      <pa-submit-proposal-left-confirm />
    }
    @default {
      <p>Oops...</p>
    }
  }
</ng-container>
