<ion-header>
  <ion-toolbar color="light">
    <ion-button fill="clear" slot="start">
      <ion-title>
        <span>
          <b>
            {{ title }}
            @if (electives) {
              {{ electives.examComponentName }}
            }
          </b>
        </span>
      </ion-title>
    </ion-button>
    <ion-button fill="clear" slot="end" (click)="dismiss()" [attr.aria-label]="'ariaLabel.closeButton' | translate">
      <ion-icon icon="close"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  @if (electives) {
    <pa-submit-proposal-content [electives]="electives" [step]="currentStep" />
  }
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-padding-horizontal">
    @switch (currentStep) {
      @case (SubmitProposalStep.Choice) {
        <ion-button slot="end" color="success" [disabled]="isNextButtonDisabled()" (click)="nextPressed()">
          {{ 'general.btnNext' | translate }}
        </ion-button>
      }
      @case (SubmitProposalStep.Confirm) {
        <ion-button fill="clear" slot="start" color="success" (click)="backPressed()">
          {{ 'general.btnPrevious' | translate }}
        </ion-button>
        <ion-button slot="end" color="success" [disabled]="isSubmitButtonDisabled()" (click)="submitPressed()">
          {{ 'submitProposal.btnSubmit' | translate }}
        </ion-button>
      }
      @default {
        Oops...
      }
    }
  </ion-toolbar>
</ion-footer>
