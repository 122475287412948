import { Component, computed, inject } from "@angular/core";
import { SubmitProposalStateService } from "@app/features/submit-proposal/services/submit-proposal-state.service";
import { StatusMessageComponent } from "@app/shared/components/status-message.component";
import {
  GenericDataList,
  GenericDataTableComponent,
} from "@shared/components/generic-data-table/generic-data-table.component";

@Component({
  selector: "pa-submit-proposal-left-choice",
  templateUrl: "./submit-proposal-left-choice.component.html",
  styleUrls: ["./submit-proposal-left-choice.component.scss"],
  standalone: true,
  imports: [GenericDataTableComponent, StatusMessageComponent],
})
export class SubmitProposalLeftChoiceComponent {
  private readonly submitProposalStateService = inject(SubmitProposalStateService);
  protected electives = this.submitProposalStateService.electives;
  protected warnings = this.submitProposalStateService.warnings;

  protected dataList = computed<GenericDataList[]>(() => {
    // Re-initialize the dataList
    const electives = this.electives();
    return electives ? this.submitProposalStateService.buildInfoTable() : [];
  });
}
