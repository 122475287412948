import { AfterViewInit, booleanAttribute, Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Role } from "@app/core/domain/user";
import { CaciUtil } from "@app/core/util/caci-util";
import { LanguageService } from "@app/shared/services/language.service";
import { PlanStateService } from "@app/shared/services/plan-state.service";
import { Constants } from "@core/constants";
import { CourseDetail, PartOfMinor } from "@core/domain/course-detail";
import { PlanHelper } from "@core/domain/helpers/plan-helper";
import { CatalogSearchInfo } from "@feature/catalog/pages/catalog-page.component";
import { IonButton, ModalController } from "@ionic/angular/standalone";
import { TranslateModule } from "@ngx-translate/core";
import { PlanActionService } from "@shared/services/plan-action.service";
import { CourseData, SelectedPeriod } from "@shared/types/course-data";
import { NgxPermissionsModule } from "ngx-permissions";
import { PlanElementComponent } from "./plan-element/plan-element.component";

@Component({
  selector: "pa-course-detail",
  templateUrl: "./course-detail.component.html",
  styleUrls: ["./course-detail.component.scss"],
  standalone: true,
  imports: [NgxPermissionsModule, PlanElementComponent, TranslateModule, IonButton],
})
export class CourseDetailComponent implements OnInit, AfterViewInit {
  @Input() courseDetail!: CourseDetail;
  @Input() courseData!: CourseData;
  @Input({ transform: booleanAttribute }) isScheduleSelectable = false;
  @Output() selectedPeriod: EventEmitter<SelectedPeriod> = new EventEmitter();
  @Output() openCatalog: EventEmitter<CatalogSearchInfo> = new EventEmitter();
  courseDetailTutors!: string[] | undefined;
  hasMinorsInCatalog = true;
  protected courseStudyPointsDisplay = "";
  protected readonly Role = Role;
  private readonly planActionService = inject(PlanActionService);
  private readonly planStateService = inject(PlanStateService);
  private readonly modalCtrl = inject(ModalController);
  private readonly router = inject(Router);
  private readonly planHelper = inject(PlanHelper);
  private readonly languageService = inject(LanguageService);

  ngOnInit(): void {
    this.courseDetailTutors = this.courseDetail?.tutors ?? [];
    this.hasMinorsInCatalog = !!this.planStateService.currentSelectedPlan()?.scheduleMinor;
    this.courseStudyPointsDisplay = CaciUtil.getStudyPointsDisplay(
      this.courseDetail.studyPoints,
      this.languageService.getLanguage(),
    );
  }

  ngAfterViewInit(): void {
    this.adjustPreRequirementNavigation();
  }

  searchMinor(partOfMinor: PartOfMinor): void {
    const minorStudyProgram = this.planHelper.stripSpaces(partOfMinor.studyProgram);
    const minorMinor = this.planHelper.stripSpaces(partOfMinor.minor);

    this.openCatalog.emit({
      code: minorMinor,
      name: `${partOfMinor.minorName}`,
      description: `${minorStudyProgram} ${partOfMinor.minorName}`,
    });
  }

  onSelectedPeriod(selectedPeriod: SelectedPeriod): void {
    this.selectedPeriod.emit(selectedPeriod);
  }

  onPlanNow(event?: MouseEvent): void {
    event && event.stopPropagation();
    this.planActionService.selectPeriodAndPlan(this.courseData, this.courseDetail);
    this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_OK);
  }

  private adjustPreRequirementNavigation(): void {
    const querySelectorA = document.getElementById("preRequirementID")?.querySelector("a");

    if (querySelectorA) {
      // Redirect the following URL https://saasdev.osiris-planapp.nl/#/plan/course?cursus=MB1100
      // to the catalog courses component
      // Based on the used Regex match array contains the following values
      // match[0]: "cursus=MB1100"
      // match[1]: "MB1100"
      const regex = /cursus=(.*)/;
      const preRequirementHREF = querySelectorA.getAttribute("href") ?? "";
      const match = regex.exec(preRequirementHREF);
      if (match) {
        // Remove href attribute
        querySelectorA.removeAttribute("href");

        // Add styling for simulation of href behaviour
        querySelectorA.style.textDecoration = "underline";
        querySelectorA.style.cursor = "pointer";

        // Add click event to use the Angular routing for going to the catalog courses page
        querySelectorA.addEventListener("click", () => {
          this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_OK);
          this.router.navigate(["/catalog/courses"], { queryParams: { course: `${match[1]}` } });
        });
      }
    }
  }
}
