import { inject, Injectable } from "@angular/core";
import { ApiExamenonderdeel, ApiVoorwaarde } from "../api/model/api-examenonderdeel";
import { ApiProfielExamenonderdeel } from "../api/model/api-profilering";
import { ExamComponent, ExamComponentCondition, MinorExamComponent } from "../domain/exam-component";
import { ApiHelper } from "../domain/helpers/api-helper";
import { CourseMappingService } from "./course-mapping.service";

@Injectable({
  providedIn: "root",
})
export class ExamComponentMappingService {
  private courseMapper = inject(CourseMappingService);

  public mapExamComponent(examenonderdeel: ApiExamenonderdeel): ExamComponent {
    return {
      id: examenonderdeel.sple_id,
      studyProgram: examenonderdeel.examenprogramma,
      examComponentCode: examenonderdeel.examenonderdeel,
      examComponentName: examenonderdeel.examenonderdeel_naam,
      examComponentExplanation: examenonderdeel.examenonderdeel_toelichting,
      label: examenonderdeel.label,
      labelColor: examenonderdeel.kleur,
      typeMandatoryChoice: examenonderdeel.type_vk,
      minimumPoints: examenonderdeel.minimum_punten,
      maximumPoints: examenonderdeel.maximum_punten,
      conditionsMet: ApiHelper.caciBooleanToBoolean(examenonderdeel.voldaan),
      conditionsMetMandatoryOrMandatoryChoice: ApiHelper.caciBooleanToBoolean(examenonderdeel.voldaan_vk),
      courses: examenonderdeel.cursussen.map((cursus) => this.courseMapper.mapCourse(cursus)),
      conditions: examenonderdeel.voorwaarden?.map((voorwaarde) => this.mapExamComponentCondition(voorwaarde)),
    };
  }

  private mapExamComponentCondition(voorwaarde: ApiVoorwaarde): ExamComponentCondition {
    return {
      section: voorwaarde.rubriek,
      sectionDescription: voorwaarde.rubriek_oms,
      sectionNumber: voorwaarde.volgnummer_rubriek,
      freeField: voorwaarde.vrij_veld,
      freeFieldDescription: voorwaarde.vrij_veld_oms,
      content: voorwaarde.inhoud,
    };
  }

  public mapMinorExamComponent(component: ApiProfielExamenonderdeel): MinorExamComponent {
    const examComponent: MinorExamComponent = {
      ...this.mapExamComponent(component),
      minorExamComponentCode: component.minor_examenonderdeel,
      minorExamComponentName: component.minor_onderdeel_naam,
      type: component.type,
    };

    return examComponent;
  }
}
