@if (electives(); as electives) {
  @if (electives.textSubmitProposal.trim().length !== 0) {
    <div class="ion-padding-bottom">{{ electives.textSubmitProposal }}</div>
  }
  @if (dataList(); as dataList) {
    <pa-generic-data-table [list]="dataList" />
  }
  @if (warnings().length) {
    <hr class="warning-divider" />
    <div>
      @for (warning of warnings(); track $index) {
        <div class="warning">
          <pa-status-message [melding]="warning" />
        </div>
      }
    </div>
  }
}
