// Plan is the high level type, for the list of plans,
// PlanDetails is the type for the plan grid, which extends Plan,
// the other part of a plan is PlanLater, that type also extends Plan.
export type Plan = {
  id: number;
  name: string;
  degreeProgram: string; // (opleiding)
  yearNr: number;
  yearCount: number;
  isDraft: boolean;
};

export enum RegisteredOrPlanned {
  REGISTERED = "I", // ingeschreven
  PLANNED = "G", // gepland
  NOT_APPLICABLE = "",
}
