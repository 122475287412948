export enum ProposalStatus {
  SUBMITTED_FOR_APPROVAL = "I", // ingediend
  REJECTED = "A", // afgekeurd
  POSITIVE_ADVICE = "P", // positief advies
  APPROVED = "G", // goedgekeurd
  RESUBMITTABLE = "O", // opnieuw in te dienen
  // never in PlanApp: "B" which means that from Osiris Docent/Begeleider they want to "save" the proposal (bewaren)
  NOT_APPLICABLE = "",
}

export enum ProposalReviewMoment {
  INTERIM = "T", // tussentijdse beoordeling
  FINAL = "E", // eindbeoordeling
  NOT_APPLICABLE = "",
}

export enum ProposalDocumentUpload {
  NOT_ALLOWED = "N", // Niet toegestaan
  OPTIONAL = "O", // Optioneel
  REQUIRED = "V", // Verplicht
}

export enum ProposalBlocksExamComponents {
  NONE = "GEEN",
  ELECTIVES = "PROFILERING",
  ALL = "ALLE",
}
