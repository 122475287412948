import { inject } from "@angular/core";
import { Course } from "@app/core/domain/course";
import { Electives } from "@app/core/domain/electives";
import { SubmitProposalStateService } from "@app/features/submit-proposal/services/submit-proposal-state.service";
import { TranslateService } from "@ngx-translate/core";
import { CourseDataMapper } from "@shared/services/course-data-mapping.service";
import { CourseData } from "@shared/types/course-data";

export type ConfirmedCourseGroup = {
  title: string;
  subTitle: string | undefined;
  courseData: CourseData[];
};

export class ConfirmCourseListBuilder {
  private readonly courseDataMapper = inject<CourseDataMapper>(CourseDataMapper);

  constructor(
    private readonly translate: TranslateService,
    private readonly submitProposalStateService: SubmitProposalStateService,
  ) {}

  buildCourseList(electives: Electives): ConfirmedCourseGroup[] {
    const coursesGroup = this.buildEmptyCourseGroup(this.translate.instant("submitProposal.courses"));

    const individualArrangementCourses = this.extractIndividualArrangement(electives);
    const minorCourseGroups = this.extractMinorCourseGroups(coursesGroup);
    this.extractCourses(electives, coursesGroup);

    return [...individualArrangementCourses, ...minorCourseGroups, coursesGroup];
  }

  private extractIndividualArrangement(electives: Electives): ConfirmedCourseGroup[] {
    const groups: ConfirmedCourseGroup[] = [];

    if (electives.individualArrangements?.length) {
      const group = this.buildEmptyCourseGroup(this.translate.instant("submitProposal.coursesIndividualArrangements"));
      electives.individualArrangements.forEach((course) => {
        group.courseData.push(this.courseDataMapper.mapCourseData(course));
      });

      group.courseData.length && groups.push(group);
    }

    return groups;
  }

  // Add all selected minor courses to minor group, unless minor points not reached. In that case add courses to "other" group.
  private extractMinorCourseGroups(otherCoursesGroup: ConfirmedCourseGroup): ConfirmedCourseGroup[] {
    const minorGroups: ConfirmedCourseGroup[] = [];
    this.submitProposalStateService.minorsInProposal
      .filter((minorInProposal) => minorInProposal.courses.length)
      .forEach((minorInProposal) => {
        if (minorInProposal.totalPoints < minorInProposal.minimumPoints) {
          // Minor minimum points not reached, courses will be added as separate courses
          otherCoursesGroup.courseData.push(
            ...minorInProposal.courses.map((course) => this.courseDataMapper.mapCourseData(course)),
          );
          // warning is handled in submitProposalStateService.buildWarningsMinorCoursesChecked
        } else {
          const minorGroup = this.buildEmptyCourseGroup(minorInProposal.name);
          minorGroup.courseData.push(
            ...minorInProposal.courses.map((course) => this.courseDataMapper.mapCourseData(course)),
          );
          minorGroups.push(minorGroup);
        }
      });

    return minorGroups;
  }

  private extractCourses(electives: Electives, otherCoursesGroup: ConfirmedCourseGroup): void {
    let confirmedCoursesInElectives: Course[] = [];
    confirmedCoursesInElectives = electives.courses.filter((course) =>
      this.submitProposalStateService.isCourseSelected(course, electives),
    );
    confirmedCoursesInElectives.forEach((course) => {
      otherCoursesGroup.courseData.push(this.courseDataMapper.mapCourseData(course));
    });
  }

  private buildEmptyCourseGroup(header: string): ConfirmedCourseGroup {
    return {
      title: header,
      subTitle: "",
      courseData: [],
    };
  }
}
