import { Injectable } from "@angular/core";
import {
  ApiPlanHistory,
  ApiPlanHistoryDetailAttachment,
  ApiPlanHistoryDetailCourse,
  ApiPlanHistoryDetailCreditsCategory,
  ApiPlanHistoryDetails,
  ApiPlanHistoryItem,
} from "@core/api/model/api-plan-history";
import {
  PlanHistory,
  PlanHistoryCategoryRequirement,
  PlanHistoryDetailAttachment,
  PlanHistoryDetailCourse,
  PlanHistoryDetailCreditsCategory,
  PlanHistoryDetails,
  PlanHistoryItem,
} from "@core/domain/plan-history";
import { ApiCategoryRequirement } from "../api/model/api-profilering";

@Injectable({
  providedIn: "root",
})
export class PlanHistoryMappingService {
  constructor() {}

  mapPlanHistory(history: ApiPlanHistory): PlanHistory {
    const planHistory = {} as PlanHistory;

    planHistory.items = history.items.map((item) => this.mapPlanHistoryItem(item)) ?? ([] as PlanHistoryItem[]);

    return planHistory;
  }

  private mapPlanHistoryItem(item: ApiPlanHistoryItem): PlanHistoryItem {
    return {
      splaId: item.spla_id,
      hsplId: item.hspl_id,
      studentNr: item.studentnummer,
      reviewMomentDescription: item.beoordelingsmoment_omschrijving,
      statusDescription: item.status_omschrijving,
      decisionDate: item.datum_besluit,
    };
  }

  mapPlanHistoryDetails(historyDetails: ApiPlanHistoryDetails): PlanHistoryDetails {
    return {
      splaId: historyDetails.spla_id,
      hsplId: historyDetails.hspl_id,
      studentNr: historyDetails.studentnummer,
      reviewMomentDescription: historyDetails.beoordelingsmoment_oms,
      submitDate: historyDetails.datum_indienen,
      studentExplanation: historyDetails.toelichting_indienen,
      adviceDate: historyDetails.datum_advies,
      adviceExplanation: historyDetails.toelichting_advies,
      adviceTitle: historyDetails.titel_advies,
      decisionDate: historyDetails.datum_besluit,
      decisionExplanation: historyDetails.toelichting_besluit,
      decisionTitle: historyDetails.titel_besluit,
      electivesName: historyDetails.profil_naam,
      electivesMinimumPoints: historyDetails.profil_minimum_punten,
      pointsSelected: historyDetails.punten_geselecteerd,
      pointsPerCategory:
        historyDetails.punten_per_categorie.map((category) => this.mapPlanHistoryDetailCreditsCategory(category)) ?? [],
      individualArrangements:
        historyDetails.individuele_regelingen.map((arrangement) => this.mapPlanHistoryDetailCourse(arrangement)) ?? [],
      courses: historyDetails.cursussen.map((course) => this.mapPlanHistoryDetailCourse(course)) ?? [],
      attachments: historyDetails.bijlagen.map((attachment) => this.mapPlanHistoryDetailAttachment(attachment)) ?? [],
      categoryRequirements:
        historyDetails.categorie_eisen.map((requirement) => this.mapCategoryRequirement(requirement)) ?? [],
    };
  }

  private mapPlanHistoryDetailCreditsCategory(
    category: ApiPlanHistoryDetailCreditsCategory,
  ): PlanHistoryDetailCreditsCategory {
    return {
      category: category.categorie,
      categoryDescription: category.categorie_oms,
      pointsSelected: category.punten_geselecteerd,
    };
  }

  private mapPlanHistoryDetailCourse(historyDetailCourse: ApiPlanHistoryDetailCourse): PlanHistoryDetailCourse {
    return {
      id: historyDetailCourse.spcu_id,
      code: historyDetailCourse.cursus,
      name: historyDetailCourse.korte_naam,
      customCourseName: historyDetailCourse.eigen_cursus,
      customCoursePoints: historyDetailCourse.eigen_cursus_punten,
      studyProgram: historyDetailCourse.examenprogramma,
      examComponentCode: historyDetailCourse.examenonderdeel,
      replaceOld: historyDetailCourse.vervanging_oud,
      replaceNew: historyDetailCourse.vervanging_nieuw,
      label: historyDetailCourse.label,
      labelColor: historyDetailCourse.kleur,
      proposalStatus: historyDetailCourse.status_goedgekeurd,
      studyPoints: historyDetailCourse.studiepunten,
      category: historyDetailCourse.categorie,
      categoryDescription: historyDetailCourse.categorie_oms,
    };
  }

  private mapPlanHistoryDetailAttachment(attachment: ApiPlanHistoryDetailAttachment): PlanHistoryDetailAttachment {
    return {
      documentId: attachment.id_document,
      fileName: attachment.bestandsnaam,
    };
  }

  private mapCategoryRequirement(requirement: ApiCategoryRequirement): PlanHistoryCategoryRequirement {
    return {
      category: requirement.categorie,
      categoryDescription: requirement.categorie_oms,
      maximumPoints: requirement.maximum_punten,
      minimumPoints: requirement.minimum_punten,
    };
  }
}
