import { inject, Injectable } from "@angular/core";
import {
  ApiCategoryRequirement,
  ApiPlanProfilering,
  ApiProfielMinor,
  ApiProfileringCursusRequest,
  ApiProfileringMinorExamenonderdeelRequest,
  ApiProfileringMinorRequest,
  ApiProposalDocumentRequest,
  ApiProposalRequest,
} from "../api/model/api-profilering";
import { Course } from "../domain/course";
import { CategoryRequirement, Electives, Minor, UploadedFileInfo } from "../domain/electives";
import { MinorExamComponent } from "../domain/exam-component";
import { ApiHelper } from "../domain/helpers/api-helper";
import { ExamComponentMappingService } from "./exam-component-mapping.service";
import { PlanMappingService } from "./plan-mapping.service";

@Injectable({
  providedIn: "root",
})
export class ElectivesMappingService {
  private readonly planMapper = inject(PlanMappingService);
  private readonly examComponentMapper = inject(ExamComponentMappingService);

  mapElectives(apiPlanProfilering: ApiPlanProfilering): Electives {
    return {
      planId: apiPlanProfilering.spla_id,
      pointsPlanned: apiPlanProfilering.studiepunten_gepland,
      examComponentCode: apiPlanProfilering.profil_code,
      examComponentName: apiPlanProfilering.profil_naam,
      draftPlan: ApiHelper.caciBooleanToBoolean(apiPlanProfilering.concept_planning),
      minorRequirementType: apiPlanProfilering.profil_minor_eis_type,
      minorRequirementTypeDescription: apiPlanProfilering.profil_minor_eis_type_oms,
      minimumPoints: apiPlanProfilering.profil_minimum_punten,
      maximumPoints: apiPlanProfilering.profil_maximum_punten,
      textSubmitProposal: apiPlanProfilering.tekst_opgeven_profilering,
      textConfirmProposal: apiPlanProfilering.tekst_bevestig_profilering,
      textConfirmUpload: apiPlanProfilering.tekst_bevestig_profilering_upload,
      maxDocumentsToSubmit: apiPlanProfilering.max_documenten_indienen,
      categoryRequirements: apiPlanProfilering.categorie_eisen?.map((eis) => this.mapCategoryRequirement(eis)) ?? [],
      individualArrangements:
        apiPlanProfilering.individuele_regelingen?.map((cursus) => this.planMapper.mapCourse(cursus)) ?? [],
      minors: apiPlanProfilering.minors?.map((minor) => this.mapMinor(minor)) ?? [],
      courses: apiPlanProfilering.losse_cursussen?.map((cursus) => this.planMapper.mapCourse(cursus)) ?? [],
    };
  }

  private mapCategoryRequirement(categorieEis: ApiCategoryRequirement): CategoryRequirement {
    return {
      categoryCode: categorieEis.categorie,
      categoryDescription: categorieEis.categorie_oms,
    };
  }

  private mapMinor(apiMinor: ApiProfielMinor): Minor {
    return {
      minor: apiMinor.minor,
      name: apiMinor.naam,
      type: apiMinor.type,
      examComponentCode: apiMinor.examenonderdeel,
      examComponents: apiMinor.examenonderdelen.map((comp) => this.examComponentMapper.mapMinorExamComponent(comp)),

      code: apiMinor.code,

      studyProgram: apiMinor.examenprogramma,
      content: apiMinor.inhoud,
      typeBama: apiMinor.type_bama,
      typePart: apiMinor.type_onderdeel,
      isRegistered: ApiHelper.caciBooleanToBoolean(apiMinor.status_ingeschreven),
      minorStudyProgram: apiMinor.minor_examenprogramma,
      minimumPoints: apiMinor.minimum_punten,
    };
  }

  mapToApiProposalRequest(
    electives: Electives,
    uploadedFiles: UploadedFileInfo[],
    explanation: string,
  ): ApiProposalRequest {
    return {
      spla_id: electives.planId,
      toelichting_indienen: explanation,
      individuele_regelingen: electives.individualArrangements?.map((course) => this.mapToApiCursus(course)) ?? [],
      minors: electives.minors?.map((minor) => this.mapToApiMinor(minor)) ?? [],
      losse_cursussen: electives.courses?.map((course) => this.mapToApiCursus(course)) ?? [],
      documenten: uploadedFiles?.map((document) => this.mapToApiDocument(document)) ?? [],
    };
  }

  private mapToApiMinor(minor: Minor): ApiProfileringMinorRequest {
    return {
      minor: minor.minor,
      minor_examenprogramma: minor.minorStudyProgram,
      examenonderdelen: minor.examComponents.map((comp) => this.mapToApiMinorExamenonderdeel(comp)),
    };
  }

  private mapToApiMinorExamenonderdeel(component: MinorExamComponent): ApiProfileringMinorExamenonderdeelRequest {
    return {
      sple_id: component.id,
      cursussen: component.courses.map((course) => this.mapToApiCursus(course)),
    };
  }

  private mapToApiCursus(course: Course): ApiProfileringCursusRequest {
    return {
      spcu_id: course.id!,
    };
  }

  private mapToApiDocument(document: UploadedFileInfo): ApiProposalDocumentRequest {
    return {
      bestandsnaam: document.file.name,
      document_grootte: document.file.size,
    };
  }
}
