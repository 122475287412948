import { Component, computed, inject, WritableSignal } from "@angular/core";
import { Electives } from "@app/core/domain/electives";
import { SubmitProposalStateService } from "@app/features/submit-proposal/services/submit-proposal-state.service";
import { AddCourseListHeaderComponent } from "@app/shared/components/add-course-list-header/add-course-list-header.component";
import { AddCourseListItemComponent } from "@app/shared/components/add-course-list-item/add-course-list-item.component";
import { CourseItemComponent } from "@app/shared/components/course-item/course-item.component";
import { CourseGroup, CourseItem } from "@feature/submit-proposal/services/choice-course-list.builder";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "pa-submit-proposal-right-choice",
  templateUrl: "./submit-proposal-right-choice.component.html",
  styleUrls: ["./submit-proposal-right-choice.component.scss"],
  standalone: true,
  imports: [AddCourseListHeaderComponent, AddCourseListItemComponent, CourseItemComponent, TranslateModule],
})
export class SubmitProposalRightChoiceComponent {
  private readonly submitProposalStateService = inject(SubmitProposalStateService);

  electives: WritableSignal<Electives | undefined> = this.submitProposalStateService.electives;
  planAllowsChangeExamComponent: boolean = this.submitProposalStateService.planAllowsChangeExamComponent();
  courseList = computed<CourseGroup[]>(() => {
    this.submitProposalStateService.electives(); // Triggers the effect...
    return this.submitProposalStateService.courseGroups;
  });

  isCourseSelectable(courseItem: CourseItem): boolean {
    return courseItem.selectable && this.submitProposalStateService.isCourseSelectable(courseItem.course);
  }

  onSelectCourse(courseItem: CourseItem, selected: boolean): void {
    courseItem.selected = selected;
    courseItem.onSelect && courseItem.onSelect();
  }
}
