import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiExamenprogrammas } from "@app/core/api/model/api-examen-programma";
import { ApiExamentypes } from "@app/core/api/model/api-examen-type";
import { ApiExamenonderdelenResponse } from "@app/core/api/model/api-examenonderdeel";
import { ApiOpleidingen } from "@app/core/api/model/api-opleiding";
import { DegreeProgram } from "@app/core/domain/degree-program";
import { Draft } from "@app/core/domain/draft";
import { ExamComponentForPlanCourse } from "@app/core/domain/exam-component";
import { ExamType } from "@app/core/domain/exam-type";
import { ApiHelper } from "@app/core/domain/helpers/api-helper";
import { StudyProgram } from "@app/core/domain/study-program";
import { ApiMappingService } from "@core/mapping/api-mapping.service";
import { GenericHttpService } from "@core/services/generic-http.service";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EducationService extends GenericHttpService {
  private mappingService = inject(ApiMappingService);

  getDegreePrograms(): Observable<DegreeProgram[]> {
    const params = new HttpParams().set("plan_app", "true");

    return this.httpGet<ApiOpleidingen>("opleidingen", params).pipe(
      map((opleidingen) => {
        return opleidingen?.items.map((apiOpleiding) => this.mappingService.toDegreeProgram(apiOpleiding)) ?? [];
      }),
    );
  }

  getExamTypes(): Observable<ExamType[]> {
    return this.httpGet<ApiExamentypes>("examentype").pipe(
      map((types) => {
        return types?.items?.map((apiExamentype) => this.mappingService.toExamType(apiExamentype)) ?? [];
      }),
    );
  }

  getStudyPrograms(
    draft: Draft,
    options: { bachelorOnly: boolean } = { bachelorOnly: false },
  ): Observable<StudyProgram[]> {
    const params = new HttpParams()
      .set("alleen_bachelor", ApiHelper.booleanToCaciBoolean(options.bachelorOnly) as string)
      .set("opleiding", draft.degreeProgram)
      .set("start_jaar", draft.startingYear)
      .set("examentype", draft.examType);

    return this.httpGet<ApiExamenprogrammas>("examenprogrammas", params).pipe(
      map((apiExamenprogrammas) => {
        return (
          apiExamenprogrammas?.items.map((apiExamenprogramma) =>
            this.mappingService.toStudyProgram(apiExamenprogramma),
          ) ?? []
        );
      }),
    );
  }

  getExamComponentsForPlanCourse(planCourseId: number): Observable<ExamComponentForPlanCourse[]> {
    const params = new HttpParams().set("spcu_id", planCourseId.toString());
    return this.httpGet<ApiExamenonderdelenResponse>("examenonderdelen", params).pipe(
      map((apiExamenonderdelenResponse) => {
        if (!apiExamenonderdelenResponse || apiExamenonderdelenResponse.examenonderdelen?.length === 0) {
          return [];
        }
        return apiExamenonderdelenResponse.examenonderdelen.map((apiExamenonderdeelVoorPlanCursus) =>
          this.mappingService.toExamComponentForPlanCourse(apiExamenonderdeelVoorPlanCursus),
        );
      }),
    );
  }
}
