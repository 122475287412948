import { inject, Injectable } from "@angular/core";
import { ApiOpleiding } from "@app/core/api/model/api-opleiding";
import { DegreeProgram } from "@app/core/domain/degree-program";
import { Electives } from "@app/core/domain/electives";
import { ExamType } from "@app/core/domain/exam-type";
import { PlanDetails, PlanDetailsResponse, StatusResponse } from "@app/core/domain/plan-details";
import { ApiCollegeJaar } from "@core/api/model/api-collegejaren";
import {
  ApiCurriculum,
  ApiCurriculumCursus,
  ApiCurriculumExamenonderdeel,
  ApiCurriculumExamenProgramma,
} from "@core/api/model/api-curriculum";
import { ApiCursusDetail } from "@core/api/model/api-cursus-detail";
import { ApiDraft, ApiDraftResponse } from "@core/api/model/api-draft";
import { ApiExamenprogramma } from "@core/api/model/api-examen-programma";
import { ApiExamentype } from "@core/api/model/api-examen-type";
import { ApiMinorInschrijving } from "@core/api/model/api-minor";
import { ApiPlan, ApiPlanDetail, ApiPlanResponse, ApiStatusResponse } from "@core/api/model/api-plan";
import { ApiPlanHistory, ApiPlanHistoryDetails } from "@core/api/model/api-plan-history";
import { ApiPlanLater } from "@core/api/model/api-plan-later";
import { AcademicYear } from "@core/domain/academic-year";
import { CourseDetail } from "@core/domain/course-detail";
import { Curriculum, CurriculumCourse, CurriculumExamComponent, CurriculumStudyProgram } from "@core/domain/curriculum";
import { Draft, DraftResponse } from "@core/domain/draft";
import { ApiHelper } from "@core/domain/helpers/api-helper";
import { MinorRegistration } from "@core/domain/minor";
import { Plan } from "@core/domain/plan";
import { PlanHistory, PlanHistoryDetails } from "@core/domain/plan-history";
import { PlanLater } from "@core/domain/plan-later";
import { StudyProgram } from "@core/domain/study-program";
import { CourseMappingService } from "@core/mapping/course-mapping.service";
import { PlanHistoryMappingService } from "@core/mapping/plan-history-mapping.service";
import { PlanLaterMappingService } from "@core/mapping/plan-later-mapping.service";
import { PlanMappingService } from "@core/mapping/plan-mapping.service";
import { ApiExamenonderdeelVoorPlanCursus } from "../api/model/api-examenonderdeel";
import { ApiPlanProfilering } from "../api/model/api-profilering";
import { ExamComponentForPlanCourse } from "../domain/exam-component";
import { ElectivesMappingService } from "./electives-mapping.service";

@Injectable({
  providedIn: "root",
})
export class ApiMappingService {
  private readonly courseMapper = inject(CourseMappingService);
  private readonly planMapper = inject(PlanMappingService);
  private readonly planLaterMapper = inject(PlanLaterMappingService);
  private readonly planHistoryMapper = inject(PlanHistoryMappingService);
  private readonly electivesMapper = inject(ElectivesMappingService);

  toPlan(apiPlan: ApiPlan): Plan {
    return {
      id: apiPlan.spla_id,
      name: apiPlan.planning_naam,
      degreeProgram: apiPlan.opleiding,
      yearNr: apiPlan.start_jaar,
      yearCount: apiPlan.aantal_jaar,
      isDraft: ApiHelper.caciBooleanToBoolean(apiPlan.concept_planning),
    };
  }

  toPlanDetails(apiPlanDetail: ApiPlanDetail, forValidation = false): PlanDetails {
    return this.planMapper.mapPlanDetails(apiPlanDetail, forValidation);
  }

  toElectives(apiPlanProfil: ApiPlanProfilering): Electives {
    return this.electivesMapper.mapElectives(apiPlanProfil);
  }

  toCourseDetail(apiCursusDetail: ApiCursusDetail): CourseDetail {
    return this.courseMapper.mapCourseDetail(apiCursusDetail);
  }

  toCurriculum(apiCurriculum: ApiCurriculum): Curriculum {
    return {
      id: apiCurriculum.spla_id,
      studentNr: apiCurriculum.studentnummer,
      studentName: apiCurriculum.studentnaam,
      staffMember: apiCurriculum.medewerker,
      planName: apiCurriculum.planning_naam,
      draft: ApiHelper.caciBooleanToBoolean(apiCurriculum.concept_planning),
      startingYear: apiCurriculum.start_jaar,
      yearCount: apiCurriculum.aantal_jaar,
      submitDate: apiCurriculum.datum_indienen,
      decisionDate: apiCurriculum.datum_besluit,
      adviceDate: apiCurriculum.datum_advies,
      lastSaved: apiCurriculum.laatst_opgeslagen,
      adviceExplanation: apiCurriculum.toelichting_advies,
      decisionExplanation: apiCurriculum.toelichting_besluit,
      proposalStatusDescription: apiCurriculum.status,
      proposalReviewMomentDescription: apiCurriculum.beoordelingsmoment,
      studyPrograms:
        apiCurriculum.examenprogrammas?.map((examenprogramma: ApiCurriculumExamenProgramma) =>
          this.toCurriculumStudyProgram(examenprogramma),
        ) ?? [],
    };
  }

  private toCurriculumStudyProgram(examenprogramma: ApiCurriculumExamenProgramma): CurriculumStudyProgram {
    return {
      degreeProgram: examenprogramma.opleiding,
      code: examenprogramma.examenprogramma,
      name: examenprogramma.naam_examenprogramma,
      examType: examenprogramma.examentype,
      minimumPoints: examenprogramma.minimum_punten,
      electivesName: examenprogramma.profil_naam,
      examComponents: examenprogramma.examenonderdelen.map((examenonderdeel: ApiCurriculumExamenonderdeel) =>
        this.toCurriculumExamenonderdeel(examenonderdeel),
      ),
    };
  }

  private toCurriculumExamenonderdeel(examenonderdeel: ApiCurriculumExamenonderdeel): CurriculumExamComponent {
    return {
      name: examenonderdeel.naam,
      order: examenonderdeel.volgorde,
      type: examenonderdeel.type,
      typeDescription: examenonderdeel.type_oms,
      level: examenonderdeel.niveau,
      studyProgram: examenonderdeel.examenprogramma,
      explanation: examenonderdeel.toelichting,
      code: examenonderdeel.code,
      subcomponentsRequirement: examenonderdeel.eis_onderliggende_onderdelen,
      minimumPoints: examenonderdeel.minimum_punten,
      pointsAchieved: examenonderdeel.behaalde_punten,
      planningState: examenonderdeel.status_planning,
      conditionsMet: ApiHelper.caciBooleanToBoolean(examenonderdeel.voldaan),
      chosen: ApiHelper.caciBooleanToBoolean(examenonderdeel.gekozen_of_tak),
      typeTemporary: examenonderdeel.type_array_TIJDELIJK,
      courses: examenonderdeel.cursussen.map((cursus) => this.toCurriculumCursus(cursus)),
      pointsPlanned: examenonderdeel.geplande_punten,
    };
  }

  private toCurriculumCursus(course: ApiCurriculumCursus): CurriculumCourse {
    return this.courseMapper.mapCurriculumCourse(course);
  }

  toPlanResponse(response: ApiPlanResponse): PlanDetailsResponse {
    if (response.statusmeldingen?.length) {
      return this.toStatusResponse(response as ApiStatusResponse);
    }

    return this.toPlanDetails(response as ApiPlanDetail);
  }

  toStatusResponse(response: ApiStatusResponse): StatusResponse {
    return {
      statusMessages: response.statusmeldingen.map((msg) => ApiHelper.toStatusMessage(msg)),
    };
  }

  toPlanLater(apiPlanLater: ApiPlanLater): PlanLater {
    return this.planLaterMapper.mapPlanLater(apiPlanLater);
  }

  toPlanHistory(history: ApiPlanHistory): PlanHistory {
    return this.planHistoryMapper.mapPlanHistory(history);
  }

  toPlanHistoryDetails(historyDetails: ApiPlanHistoryDetails): PlanHistoryDetails {
    return this.planHistoryMapper.mapPlanHistoryDetails(historyDetails);
  }

  toExamType(apiExamentype: ApiExamentype): ExamType {
    return {
      code: apiExamentype.examentype,
      description: apiExamentype.omschrijving_nls,
    };
  }

  toDegreeProgram(apiDegreeProgram: ApiOpleiding): DegreeProgram {
    return {
      examType: apiDegreeProgram.examentype,
      name: apiDegreeProgram.naam,
      code: apiDegreeProgram.opleiding,
    };
  }

  toStudyProgram(apiExamenprogramma: ApiExamenprogramma): StudyProgram {
    return {
      id: apiExamenprogramma.exam_id,
      code: apiExamenprogramma.examenprogramma,
      showSecondStudyProgram: ApiHelper.caciBooleanToBoolean(apiExamenprogramma.tweede_examenprogramma_tonen),
      name: apiExamenprogramma.naam,
      isRegisteredByThisStudent: ApiHelper.caciBooleanToBoolean(apiExamenprogramma.examenprogramma_student),
      isChangeExamComponentAllowed: ApiHelper.caciBooleanToBoolean(apiExamenprogramma.plaats_in_onderdeel_via_planapp),
    };
  }

  toExamComponentForPlanCourse(
    apiExamenonderdeelVoorPlanCursus: ApiExamenonderdeelVoorPlanCursus,
  ): ExamComponentForPlanCourse {
    return {
      studyProgram: apiExamenonderdeelVoorPlanCursus.examenprogramma,
      examComponentCode: apiExamenonderdeelVoorPlanCursus.examenonderdeel,
      examComponentName: apiExamenonderdeelVoorPlanCursus.naam_examenonderdeel,
      type: apiExamenonderdeelVoorPlanCursus.type,
    };
  }

  toApiDraft(draft: Draft): ApiDraft {
    return {
      opleiding: draft.degreeProgram,
      examenprogramma: draft.studyProgramCode ?? "",
      planning_naam: draft.name ?? "",
      start_jaar: draft.startingYear,
      tweede_examenprogramma: draft.secondStudyProgramCode ?? "",
      examen_type: draft.examType,
    };
  }

  toDraftResponse(apiDraftResponse: ApiDraftResponse): DraftResponse {
    return {
      id: apiDraftResponse.spla_id,
      statusMessages: apiDraftResponse.statusmeldingen.map((msg) => ApiHelper.toStatusMessage(msg)),
    };
  }

  toAcademicYear(response: ApiCollegeJaar): AcademicYear {
    return {
      year: response.collegejaar,
      description: response.collegejaar_oms,
    };
  }

  toMinorInschrijving(item: ApiMinorInschrijving): MinorRegistration {
    return {
      minor: item.minor,
      academicYear: item.collegejaar,
      startingBlock: item.aanvangsblok,
      os_uitschr: item.os_uitschr,
    };
  }
}
