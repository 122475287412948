import { Injectable, computed, signal } from "@angular/core";
import { MainPage } from "@app/shared/types/pages";
import { StatusMessage } from "@core/domain/status-message";

@Injectable({
  providedIn: "root",
})
export class StatusMessageService {
  planStatusMessages = signal<StatusMessage[] | undefined>(undefined);
  catalogStatusMessages = signal<StatusMessage[] | undefined>(undefined);
  curriculumStatusMessages = signal<StatusMessage[] | undefined>(undefined);
  mainPage: MainPage = MainPage.PLAN;

  readonly hasPlanStatusMessages = computed<boolean>(() => {
    return !!this.planStatusMessages()?.length;
  });
  readonly hasCatalogStatusMessages = computed<boolean>(() => {
    return !!this.catalogStatusMessages()?.length;
  });
  readonly hasCurriculumStatusMessages = computed<boolean>(() => {
    return !!this.curriculumStatusMessages()?.length;
  });

  setStatusMessages(statusMessages?: StatusMessage[], forPage?: MainPage): void {
    // page defaults to PLAN page
    const page = forPage ?? this.mainPage;
    switch (page) {
      case MainPage.PLAN:
        this.planStatusMessages.set(statusMessages);
        break;
      case MainPage.CATALOG:
        this.catalogStatusMessages.set(statusMessages);
        break;
      case MainPage.CURRICULUM:
        this.curriculumStatusMessages.set(statusMessages);
        break;
    }
    if (statusMessages?.length) {
      // scroll to the messages at the top of the page, so they are visible
      document.querySelector("pa-messages-alert")?.scrollIntoView({ behavior: "smooth" });
    }
  }

  clearStatusMessages(): void {
    switch (this.mainPage) {
      case MainPage.PLAN:
        this.planStatusMessages.set(undefined);
        break;
      case MainPage.CATALOG:
        this.catalogStatusMessages.set(undefined);
        break;
      case MainPage.CURRICULUM:
        this.curriculumStatusMessages.set(undefined);
        break;
    }
  }

  clearAllStatusMessages(): void {
    this.planStatusMessages.set(undefined);
    this.catalogStatusMessages.set(undefined);
    this.curriculumStatusMessages.set(undefined);
  }

  setMainPage(mainPage: MainPage): void {
    this.mainPage = mainPage;
  }
}
