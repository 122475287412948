<div class="submit-proposal-right">
  @switch (step) {
    @case (SubmitProposalStep.Choice) {
      <pa-submit-proposal-right-choice />
    }
    @case (SubmitProposalStep.Confirm) {
      <pa-submit-proposal-right-confirm />
    }
    @default {
      <p>Oops...</p>
    }
  }
</div>
