import { inject, Injectable } from "@angular/core";
import { Document, Period, PlanDetails, StudyYear } from "@app/core/domain/plan-details";
import { ApiCursus } from "@core/api/model/api-cursus";
import { ApiDocument, ApiPlanDetail, ApiPlanPeriode, ApiStudiejaar } from "@core/api/model/api-plan";
import { Course } from "@core/domain/course";
import { ApiHelper } from "@core/domain/helpers/api-helper";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { StatusMessageHelper } from "@core/domain/helpers/status-message-helper";
import { TranslateService } from "@ngx-translate/core";
import { ApiStatusmelding } from "../api/model/api-statusmelding";
import { ApiToets } from "../api/model/api-toets";
import { CourseTest } from "../domain/course-test";
import { PlanHelper } from "../domain/helpers/plan-helper";
import { StatusMessage, StatusMessageType } from "../domain/status-message";
import { CaciUtil } from "../util/caci-util";
import { CourseMappingService } from "./course-mapping.service";

@Injectable({
  providedIn: "root",
})
export class PlanMappingService {
  private readonly courseHelper = inject(CourseHelper);
  private readonly courseMappingService = inject(CourseMappingService);
  private readonly messageHelper = inject(StatusMessageHelper);
  private readonly planHelper = inject(PlanHelper);
  private readonly translate = inject(TranslateService);

  private plan!: PlanDetails;
  private periodDescr = "";

  private initPlanAllowsChangeExamComponent(apiPlan: ApiPlanDetail): boolean {
    return ApiHelper.caciBooleanToBoolean(apiPlan.plaats_in_onderdeel_via_planapp_toegestaan);
    // uitgesteld, zie OB-30864: && !ApiHelper.caciBooleanToBoolean(apiPlan.concept_planning)
  }

  mapPlanDetails(apiPlan: ApiPlanDetail, forValidation = false): PlanDetails {
    this.plan = {
      allowsChangeExamComponent: this.initPlanAllowsChangeExamComponent(apiPlan),
      credits: apiPlan.studiepunten_behaald,
      creditsPlannedPast: CaciUtil.roundStudyPoints(
        (apiPlan.studiepunten_gepland_totaal ?? 0) - (apiPlan.studiepunten_gepland ?? 0), // W 28634
      ),
      creditsTotalObtained: apiPlan.studiepunten_behaald_totaal,
      creditsTotalPlanned: apiPlan.studiepunten_gepland_totaal,
      currentYear: apiPlan.huidige_jaar,
      degreeProgram: apiPlan.opleiding,
      documents: apiPlan.documenten?.map((document) => this.mapDocument(document)),
      electivesName: apiPlan.profil_naam,
      examStage: apiPlan.examenfase,
      examType: apiPlan.examentype,
      examTypeCode: apiPlan.examentype_code,
      graduationRequestStatus: apiPlan.student_diploma_aanvraag_status,
      hasSpecialisations: ApiHelper.caciBooleanToBoolean(apiPlan.heeft_specialisaties),
      id: apiPlan.spla_id,
      isDraft: ApiHelper.caciBooleanToBoolean(apiPlan.concept_planning),
      lastSaved: apiPlan.laatst_opgeslagen,
      name: apiPlan.planning_naam,
      pointsPlanned: apiPlan.studiepunten_gepland,
      proposalAdviceDate: apiPlan.datum_advies,
      proposalAdviceExplanation: apiPlan.toelichting_advies,
      proposalAdviceStatus: apiPlan.status_advies,
      proposalBlocksExamComponents: apiPlan.blokkeer_examenonderdelen,
      proposalDecisionDate: apiPlan.datum_besluit,
      proposalDecisionExplanation: apiPlan.toelichting_besluit,
      proposalDocumentToUpload: apiPlan.document_uploaden,
      proposalNoResubmitWithGraduationRequestStatuses: apiPlan.niet_opnieuw_indienen_bij_dipl_aanvr,
      proposalResubmitAllowedByStudyProgram: ApiHelper.caciBooleanToBoolean(apiPlan.opnieuw_indienen),
      proposalReviewMoment: apiPlan.beoordelingsmoment,
      proposalStatus: apiPlan.status,
      proposalStatusAdviceBy: apiPlan.status_advies_door,
      proposalStatusBy: apiPlan.status_door,
      proposalSubmitAllowedByStudyProgram: ApiHelper.caciBooleanToBoolean(apiPlan.planning_indienen),
      proposalSubmitDate: apiPlan.datum_indienen,
      proposalSubmitMinPointsObtained: apiPlan.indienen_min_punten_behaald,
      proposalSubmitMinPointsPlanned: apiPlan.indienen_min_punten_gepland,
      scheduleMinor: ApiHelper.caciBooleanToBoolean(apiPlan.kan_minor_inplannen),
      secondExamStage: apiPlan.tweede_examenfase,
      secondExamTypeCode: apiPlan.tweede_examentype_code,
      secondStudyProgram: apiPlan.tweede_examenprogramma,
      secondStudyProgramObtained: ApiHelper.caciBooleanToBoolean(apiPlan.tweede_examenprogramma_behaald),
      staffMember: apiPlan.medewerker,
      startingYear: apiPlan.aanvangsjaar_student,
      statusMessages: undefined,
      studentName: apiPlan.studentnaam,
      studentNr: apiPlan.studentnummer,
      studyProgram: apiPlan.examenprogramma,
      studyProgramObtained: ApiHelper.caciBooleanToBoolean(apiPlan.examenprogramma_behaald),
      studyYears: this.mapStudyYears(apiPlan.studiejaren),
      validationAllowed: ApiHelper.caciBooleanToBoolean(apiPlan.is_validatie_toegestaan),
      yearCount: apiPlan.aantal_jaar,
      yearNr: apiPlan.start_jaar,
    };

    this.extractStatusMessages(apiPlan, forValidation);
    this.determinePeriodDescription();

    this.plan.studyYears.forEach((year) => {
      this.enrichRibbonCourses(year);
      this.enrichCourses(this.plan.id, year);
      this.enrichWithTests(year);
    });

    return this.plan;
  }

  mapDocument(document: ApiDocument): Document {
    return {
      documentName: document.bestandsnaam,
      documentSize: document.document_grootte,
      documentId: document.doc_id,
    };
  }

  private mapStudyYears(studiejaren: ApiStudiejaar[]) {
    return studiejaren?.filter((year) => year.periodes?.length)?.map((year) => this.mapStudyYear(year)) ?? [];
  }

  private mapStudyYear(studiejaar: ApiStudiejaar): StudyYear {
    return {
      yearNr: studiejaar.studiejaar,
      academicYear: studiejaar.collegejaar,
      academicYearDescription: studiejaar.collegejaar_oms,
      periods: studiejaar.periodes.map((periode) => this.mapPeriod(periode, studiejaar.studiejaar)),
    };
  }

  private mapPeriod(periode: ApiPlanPeriode, yearNr: number): Period {
    return {
      periodNr: periode.periode_nr,
      periodDescription: periode.periode_oms,
      actual: ApiHelper.caciBooleanToBoolean(periode.actueel),
      courses: periode.cursussen.map((cursus) => {
        const periodDescription = this.courseHelper.stripPeriodDescriptionNumber(periode.periode_oms);
        return this.mapCourse(cursus, yearNr, periode.periode_nr, periodDescription);
      }),
      courseTests: periode.toetsen?.map((toets) => this.mapTest(toets)),
    };
  }

  public mapCourse(apiCursus: ApiCursus, yearNr = 0, periodNr = 0, periodDescr = ""): Course {
    const course = this.courseMappingService.mapCourse(apiCursus);
    course.yearNr = yearNr; // If set, the course has been planned
    course.periodNr = periodNr;
    course.periodDescription = periodDescr;
    return course;
  }

  private mapTest(toets: ApiToets): CourseTest {
    return {
      courseCode: toets.cursus,
      courseName: toets.cursus_korte_naam,
      testCode: toets.toets,
      testDescription: toets.toets_omschrijving,
    };
  }

  private sortApiStatusmeldingenOnColumn(statusmeldingA: ApiStatusmelding, statusmeldingB: ApiStatusmelding): number {
    return statusmeldingA.kolom.localeCompare(statusmeldingB.kolom);
  }

  private extractStatusMessages(apiPlan: ApiPlanDetail, forValidation = false) {
    this.plan.statusMessages = [];
    if (apiPlan.statusmeldingen) {
      apiPlan.statusmeldingen.sort(this.sortApiStatusmeldingenOnColumn);
      const messages = apiPlan.statusmeldingen.map((msg) => ApiHelper.toStatusMessage(msg));
      if (messages?.length) {
        this.plan.statusMessages = messages.filter((msg) => this.messageHelper.isGenericStatusMessage(msg));
        if ((forValidation && !this.plan.statusMessages) || this.plan.statusMessages.length === 0) {
          // We need a plan-level message to ensure display of lower-level validation messages,
          // and to allow dismissal of the messages
          const validationMessage: StatusMessage = {
            code: 0,
            message: this.translate.instant("schedule.labelValidateFailed"),
            type: StatusMessageType.WARNING,
          };
          this.plan.statusMessages = [validationMessage];
        }
      } else if (forValidation) {
        // no validation messages
        const validationMessage: StatusMessage = {
          code: 0,
          message: this.translate.instant("schedule.labelValidateSuccess"),
          type: StatusMessageType.WARNING,
        };
        this.plan.statusMessages = [validationMessage];
      }

      this.enrichCoursesAndPeriodsWithStatusMessages(messages);
    }
  }

  private enrichCoursesAndPeriodsWithStatusMessages(messages: StatusMessage[]) {
    // Enrich courses and periods with status messages, or clean them up if there are none
    for (const studiejaar of this.plan.studyYears) {
      for (const period of studiejaar.periods) {
        period.statusMessages = messages.filter((msg) =>
          this.messageHelper.isPeriodStatusMessage(msg, studiejaar.yearNr, period.periodNr),
        );

        for (const course of period.courses) {
          course.statusMessages = messages.filter((msg) =>
            this.messageHelper.isCourseStatusMessage(msg, studiejaar.yearNr, period.periodNr, course.code),
          );
        }
      }
    }
  }

  private enrichRibbonCourses(year: StudyYear): void {
    const ribbonCourses: Course[] = [];

    year.periods.forEach((period) => {
      period.courses.forEach((course) => {
        if (this.courseHelper.hasRibbonPeriods(course)) {
          ribbonCourses.push(course);
        }
      });
    });

    ribbonCourses.forEach((course) => {
      this.courseHelper.planRibbonCourses(this.plan, course);
    });
  }

  private enrichCourses(id: number, year: StudyYear) {
    year.periods.forEach((period) => {
      period.courses.forEach((course: Course) => {
        course.planId = id;
        course.academicYear = year.academicYear;
        course.periodNr = period.periodNr;
        course.periodDescription = this.periodDescr;
      });

      period.courses = this.courseHelper.sortCourses(period.courses);
    });
  }

  /**
   * If a period includes info about tests that the student registered for,
   * enrich the courses in that period with the number of tests for each course.
   * If there are registered tests for which there is no course in the period,
   * add the test counts to the period object separately.
   * This should be done after enriching with ribbon courses,
   * so that test counts can be added to ribbon course repeats as well.
   */
  private enrichWithTests(year: StudyYear) {
    year.periods.forEach((period) => {
      this.planHelper.addCourseTests(period);
    });
  }

  private determinePeriodDescription(): void {
    // Determine period description to show in toast after course is planned
    if (this.plan.studyYears.length > 0 && this.plan.studyYears[0].periods.length) {
      this.periodDescr = this.courseHelper.stripPeriodDescriptionNumber(
        this.plan.studyYears[0].periods[0].periodDescription,
      );
    }
  }
}
