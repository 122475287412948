import { Component, computed, inject } from "@angular/core";
import { SubmitProposalStateService } from "@app/features/submit-proposal/services/submit-proposal-state.service";
import { AddCourseListHeaderComponent } from "@app/shared/components/add-course-list-header/add-course-list-header.component";
import { CourseItemComponent } from "@app/shared/components/course-item/course-item.component";
import {
  ConfirmCourseListBuilder,
  ConfirmedCourseGroup,
} from "@feature/submit-proposal/services/confirm-course-list.builder";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

@Component({
  selector: "pa-submit-proposal-right-confirm",
  templateUrl: "./submit-proposal-right-confirm.component.html",
  styleUrls: ["./submit-proposal-right-confirm.component.scss"],
  standalone: true,
  imports: [AddCourseListHeaderComponent, CourseItemComponent, TranslatePipe],
})
export class SubmitProposalRightConfirmComponent {
  private readonly submitProposalStateService = inject(SubmitProposalStateService);
  private readonly translate = inject(TranslateService);

  electives = this.submitProposalStateService.electives;
  selectedCourseList = computed<ConfirmedCourseGroup[]>(() => {
    const electives = this.electives();
    return electives ? this.confirmCourseListBuilder.buildCourseList(electives) : [];
  });

  private readonly confirmCourseListBuilder: ConfirmCourseListBuilder;

  constructor() {
    this.confirmCourseListBuilder = new ConfirmCourseListBuilder(this.translate, this.submitProposalStateService);
  }
}
