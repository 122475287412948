<ion-header>
  <ion-toolbar color="light">
    <ion-button fill="clear" slot="start">
      <ion-title>
        {{ 'changeExamComponent.placeInExamComponent' | translate }}
      </ion-title>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <p class="ion-padding-horizontal">
    {{
      'changeExamComponent.placeCourseInFollowingComponent'
        | translate: { courseCode: course.code, courseName: getCourseName() }
    }}
  </p>
  <ion-list lines="none">
    <ion-item>
      <ion-radio-group [value]="currentExamComponentCode" (ionChange)="handleChange($event)">
        @for (examComponentToChoose of examComponentsToChooseFrom; track examComponentToChoose.examComponentCode) {
          <ion-radio
            [value]="examComponentToChoose.examComponentCode"
            labelPlacement="end"
            justify="start"
            [disabled]="isLockedProposalComponent(examComponentToChoose)"
            [ngClass]="{ 'exam-component-other': isExamComponentOther(examComponentToChoose) }"
          >
            {{ examComponentToChoose.examComponentName }}
          </ion-radio>
          @if (isLockedProposalComponent(examComponentToChoose)) {
            <div class="locked-proposal-text">
              {{
                'changeExamComponent.lockedProposalExplanation'
                  | translate: { examComponentName: examComponentToChoose.examComponentName }
              }}
            </div>
          }
        }
      </ion-radio-group>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-padding-horizontal">
    <ion-button class="confirm-cancel" (click)="cancel()" fill="clear" color="primary" slot="start">
      {{ 'label.cancel' | translate }}
    </ion-button>
    <ion-button class="confirm-button" (click)="confirm()" fill="solid" color="success" slot="end">
      {{ 'label.ok' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
