import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { MainPage } from "@app/shared/types/pages";
import { ApiStatusResponse } from "@core/api/model/api-plan";
import { Constants } from "@core/constants";
import { ApiHelper } from "@core/domain/helpers/api-helper";
import { environment } from "@environment/environment";
import { StatusMessageService } from "@feature/plan/services/status-message.service";
import { WA_WINDOW } from "@ng-web-apis/common";
import { TranslateService } from "@ngx-translate/core";
import { NEVER, Observable, catchError, map } from "rxjs";
import { HttpOptions } from "../domain/http";
import { TokenService } from "./token.service";

type GenericHeader = { [name: string]: string };

@Injectable({
  providedIn: "root",
})
export abstract class GenericHttpService {
  private readonly baseUrl: string;
  protected router = inject(Router);
  protected httpClient = inject(HttpClient);
  protected translateService = inject(TranslateService);
  protected window = inject<Window>(WA_WINDOW);
  protected statusMessageService = inject(StatusMessageService);
  protected tokenService = inject(TokenService);

  constructor() {
    this.baseUrl = `${environment.url}`;
  }

  protected httpDelete<T>(
    urlPath: string,
    params: HttpParams = {} as HttpParams,
    headers: GenericHeader = {},
  ): Observable<T> {
    const url = `${this.baseUrl}/${urlPath}`;
    const options: HttpOptions = {
      headers: this.getHeaders(headers),
      params,
    };
    return this.httpClient.delete<T>(url, options).pipe(
      // tap((data) => {
      //   console.log(`httpDelete /${urlPath}, result:`, data);
      // }),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
    );
  }

  protected httpGet<T>(
    urlPath: string,
    params: HttpParams = {} as HttpParams,
    headers: GenericHeader = {},
    forPage: MainPage = MainPage.PLAN,
    extraOptions?: HttpOptions,
  ): Observable<T | undefined> {
    const url = `${this.baseUrl}/${urlPath}`;
    const options: HttpOptions = {
      headers: this.getHeaders(headers),
      params,
    };
    if (extraOptions) {
      // add the properties of extraOptions to options
      Object.assign(options, extraOptions);
    }

    return this.httpClient.get<T>(url, options).pipe(
      // tap((response) => console.log(`httpGet /${urlPath}, result:`, response)),
      map((response) => this.handleResponseStatusMessages(response, forPage)),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
    );
  }

  private handleResponseStatusMessages<T>(response: T, forPage: MainPage): T | undefined {
    if (response) {
      // Examine response to see if it contains only statusmeldingen
      const responsePropertyNames = Object.getOwnPropertyNames(response);
      if (
        responsePropertyNames &&
        responsePropertyNames.length === 1 &&
        responsePropertyNames[0] === "statusmeldingen"
      ) {
        // in this case response can be cast to type ApiStatusResponse)
        const apiStatusResponse = response as unknown as ApiStatusResponse;
        const statusMessages = apiStatusResponse.statusmeldingen.map((apiStatusmelding) =>
          ApiHelper.toStatusMessage(apiStatusmelding),
        );
        this.statusMessageService.setStatusMessages(statusMessages, forPage);
        return undefined;
      }
    }

    return response;
  }

  protected httpPost<T>(urlPath: string, params?: HttpParams, body?: string | File): Observable<T> {
    const url = `${this.baseUrl}/${urlPath}`;
    const options: HttpOptions = {
      headers: this.getHeaders(),
      params: params ?? ({} as HttpParams),
    };

    return this.httpClient.post<T>(url, body, options).pipe(
      // tap((data) => {
      //   console.log(`httpPost /${urlPath}, result:`, data);
      // }),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
    );
  }

  protected httpPut<T>(
    urlPath: string,
    params?: HttpParams,
    body?: string | File,
    headers: GenericHeader = {},
  ): Observable<T> {
    const url = `${this.baseUrl}/${urlPath}`;
    const options: HttpOptions = {
      headers: this.getHeaders(headers),
      params: params ?? ({} as HttpParams),
    };

    return this.httpClient.put<T>(url, body, options).pipe(
      // tap((data) => {
      //   console.log(`httpPut /${urlPath}, result:`, data);
      // }),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
    );
  }

  protected httpGetBlob(urlPath: string, headers: GenericHeader): Observable<Blob> {
    const url = `${this.baseUrl}/${urlPath}`;

    return this.httpClient
      .get(url, {
        headers: this.getHeaders(headers),
        responseType: "blob",
      })
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    const redirectUrl = this.extractRedirectUrlFromResponse(error);
    if (redirectUrl !== undefined) {
      const isLoginPage = this.window.location.href.includes("/login");
      // Normal flow: user is not yet authenticated, redirect to login page
      if (!isLoginPage) {
        // Redirecting to a page outside this application.
        this.window.location.href = redirectUrl;
      }
      return NEVER;

      // After login the app returns in http://localhost:4200/#access_token=<token>&token_type=bearer&expires_in=0
    }
    throw error;
  }

  protected extractRedirectUrlFromResponse(
    response: HttpErrorResponse | HttpResponse<string> | string | undefined,
  ): string | undefined {
    if (!response) {
      return undefined;
    }

    const paramRedirectUrl = "Authenticate-Redirect-Url";
    if (response instanceof HttpErrorResponse) {
      return response.error[paramRedirectUrl];
    }

    if (response instanceof HttpResponse) {
      // don't follow SonarQube suggestion to use the nullish coalescing operator "??"
      // because it is not equivalent when the left side is an empty string ""
      const json = response.body || "{}";
      return JSON.parse(json)[paramRedirectUrl];
    }

    if (typeof response === "object") {
      return response[paramRedirectUrl];
    }

    return JSON.parse(response)[paramRedirectUrl];
  }

  private getHeaders(headers: GenericHeader = {}): HttpHeaders {
    const lang = this.translateService.currentLang || Constants.LANGUAGE_NL;
    const token = `${this.tokenService.getTokenType() ?? ""} ${this.tokenService.getAccessToken() ?? ""}`;

    return new HttpHeaders({
      authorization: `${token}`,
      "content-type": "application/json",
      taal: lang.toUpperCase(),
      // similar caching settings as Osiris Student, prevent outdated nl.json and en.json
      "Cache-Control": "no-cache, no-store",
      Pragma: "no-cache",
      // additonal headers
      ...headers,
    });
  }

  protected getStudentNrHeader(studentNr: string): GenericHeader {
    return {
      "OS-STUDENTNUMMER": studentNr,
    };
  }
}
