<ion-header>
  <ion-toolbar color="light">
    <ion-button fill="clear" slot="start">
      <ion-title>
        {{ title }}
      </ion-title>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="new-draft-container">
    <ion-list>
      <div class="ion-padding-start">
        <ion-input
          label="{{ 'draftMenu.name' | translate }}"
          labelPlacement="floating"
          class="new-draft-name"
          [(ngModel)]="draft.name"
          required
          [maxlength]="Constants.MAX_LENGTH_PLAN_NAME"
          counter
        />
      </div>

      <ion-item>
        <ion-select
          [(ngModel)]="draft.startingYear"
          interface="popover"
          label="{{ 'draftMenu.startYear' | translate }}"
          labelPlacement="floating"
          (ionChange)="contextForStudyProgramsChanged()"
        >
          @for (startYear of startYears; track $index) {
            <ion-select-option [value]="startYear.value">{{ startYear.label }}</ion-select-option>
          }
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-select
          [(ngModel)]="draft.examType"
          interface="popover"
          label="{{ 'draftMenu.examType' | translate }}"
          labelPlacement="floating"
          (ionChange)="examTypeChanged()"
        >
          @for (examType of examTypes; track examType.code) {
            <ion-select-option [value]="examType.code">{{ examType.description }}</ion-select-option>
          }
        </ion-select>
      </ion-item>

      <ion-item (click)="select.open()">
        @if (draft) {
          <ion-input
            label="{{ 'draftMenu.degreeProgram' | translate }}"
            labelPlacement="floating"
            [ngModel]="degreeProgramName"
          />
        }

        <pa-searchable-select
          title="{{ 'draftMenu.degreeProgram' | translate }}"
          [data]="searchableDegreeProgramsForExamType"
          itemTextField="value"
          #select
          (selectedChanged)="degreeProgramChanged($event)"
        ></pa-searchable-select>
      </ion-item>

      <ion-item>
        <ion-select
          [disabled]="disableStudyProgram"
          [(ngModel)]="draft.studyProgramCode"
          interface="popover"
          label="{{ 'draftMenu.studyProgram' | translate }}"
          labelPlacement="floating"
          (ionChange)="studyProgramChanged()"
        >
          @for (studyprogram of studyPrograms; track studyprogram.id) {
            <ion-select-option [value]="studyprogram.code">{{ studyprogram.name }}</ion-select-option>
          }
        </ion-select>
      </ion-item>
      <ng-template [ngxPermissionsExcept]="Role.EMPLOYEE_VIEWS_STUDENT_PLAN">
        @if (showStudentWarning(studyPrograms, draft.studyProgramCode)) {
          <ion-item lines="none">
            <ion-text color="danger">
              {{ 'draftMenu.noStudyProgramRegistrationFound' | translate }}
            </ion-text>
          </ion-item>
        }
      </ng-template>

      @if (secondStudyPrograms.length) {
        <ion-item>
          <ion-select
            [(ngModel)]="draft.secondStudyProgramCode"
            interface="popover"
            label="{{ 'draftMenu.secondStudyProgram' | translate }}"
            labelPlacement="floating"
            (ionChange)="secondStudyProgramChanged()"
          >
            @for (secondStudyProgram of secondStudyPrograms; track secondStudyProgram.id) {
              <ion-select-option [value]="secondStudyProgram.code">{{ secondStudyProgram.name }}</ion-select-option>
            }
          </ion-select>
        </ion-item>
        <ng-template [ngxPermissionsOnly]="Role.STUDENT">
          @if (showStudentWarning(secondStudyPrograms, draft.secondStudyProgramCode)) {
            <ion-item lines="none">
              <ion-text color="danger">
                {{ 'draftMenu.noStudyProgramRegistrationFound' | translate }}
              </ion-text>
            </ion-item>
          }
        </ng-template>
        @if (showErrorIncompatibleStudyPrograms) {
          <ion-item lines="none">
            <ion-text color="danger">
              {{ 'draftMenu.studyProgramsNotCompatible' | translate }}
            </ion-text>
          </ion-item>
        }
      }
    </ion-list>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar class="ion-padding-horizontal">
    <ion-button class="confirm-cancel" (click)="dismiss()" fill="clear" color="primary" slot="start">
      {{ textCancel | translate }}
    </ion-button>
    <ion-button
      class="confirm-button"
      (click)="confirm()"
      [disabled]="!isComplete()"
      fill="solid"
      color="success"
      slot="end"
    >
      {{ textOK | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
