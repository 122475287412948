import { Component, Input } from "@angular/core";
import { SubmitProposalStep } from "@app/features/submit-proposal/services/submit-proposal-state.service";
import { SubmitProposalRightChoiceComponent } from "../submit-proposal-right-choice/submit-proposal-right-choice.component";
import { SubmitProposalRightConfirmComponent } from "../submit-proposal-right-confirm/submit-proposal-right-confirm.component";

@Component({
  selector: "pa-submit-proposal-right",
  templateUrl: "./submit-proposal-right.component.html",
  styleUrls: ["./submit-proposal-right.component.scss"],
  standalone: true,
  imports: [SubmitProposalRightChoiceComponent, SubmitProposalRightConfirmComponent],
})
export class SubmitProposalRightComponent {
  @Input() step!: SubmitProposalStep;
  protected readonly SubmitProposalStep = SubmitProposalStep;
}
