import { Component, Input } from "@angular/core";
import { SubmitProposalStep } from "@app/features/submit-proposal/services/submit-proposal-state.service";
import { Constants } from "@core/constants";
import { SubmitProposalLeftChoiceComponent } from "../submit-proposal-left-choice/submit-proposal-left-choice.component";
import { SubmitProposalLeftConfirmComponent } from "../submit-proposal-left-confirm/submit-proposal-left-confirm.component";

@Component({
  selector: "pa-submit-proposal-left",
  templateUrl: "./submit-proposal-left.component.html",
  styleUrls: ["./submit-proposal-left.component.scss"],
  standalone: true,
  imports: [SubmitProposalLeftChoiceComponent, SubmitProposalLeftConfirmComponent],
})
export class SubmitProposalLeftComponent {
  @Input() step!: SubmitProposalStep;

  protected readonly Constants = Constants;
  protected readonly SubmitProposalStep = SubmitProposalStep;
}
