import { Component, effect, EventEmitter, Input, input, InputSignal, Output } from "@angular/core";
import { IonCheckbox } from "@ionic/angular/standalone";

@Component({
  selector: "pa-add-course-list-item",
  templateUrl: "./add-course-list-item.component.html",
  styleUrls: ["./add-course-list-item.component.scss"],
  standalone: true,
  imports: [IonCheckbox],
})
export class AddCourseListItemComponent {
  @Input() showCheckbox = true;
  selected: InputSignal<boolean> = input(false);
  selectable: InputSignal<boolean> = input(true);
  @Input() disabled = false;
  @Output() selectCourse = new EventEmitter<boolean>();

  protected checked = this.selected();

  constructor() {
    effect(() => {
      // when selected changes outside this component, update checked
      this.checked = this.selected();
    });
  }

  onToggle(): void {
    this.checked = !this.checked;
    this.selectCourse.emit(this.checked);
  }
}
