import { Component, effect, inject, OnInit } from "@angular/core";
import { Electives } from "@app/core/domain/electives";
import {
  SubmitProposalStateService,
  SubmitProposalStep,
} from "@app/features/submit-proposal/services/submit-proposal-state.service";
import { Constants } from "@core/constants";
import { PlanService } from "@core/services/plan.service";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { SubmitProposalContentComponent } from "../submit-proposal-content/submit-proposal-content.component";

@Component({
  selector: "pa-submit-proposal-modal",
  templateUrl: "./submit-proposal-modal.component.html",
  styleUrls: ["./submit-proposal-modal.component.scss"],
  standalone: true,
  imports: [
    SubmitProposalContentComponent,
    TranslatePipe,
    IonHeader,
    IonToolbar,
    IonButton,
    IonTitle,
    IonIcon,
    IonContent,
    IonFooter,
  ],
})
export class SubmitProposalModalComponent implements OnInit {
  readonly Constants = Constants;
  readonly SubmitProposalStep = SubmitProposalStep;
  electives: Electives | undefined = undefined;
  currentStep: SubmitProposalStep = SubmitProposalStep.Choice;
  title = "";
  private readonly planService = inject(PlanService);
  private readonly submitProposalStateService = inject(SubmitProposalStateService);
  private readonly modalCtrl = inject(ModalController);
  private readonly translate = inject(TranslateService);

  constructor() {
    effect(() => {
      this.electives = this.submitProposalStateService.electives();
      this.determineTitle();
    });
  }

  ngOnInit(): void {
    this.determineTitle();
  }

  dismiss(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_CANCEL);
  }

  private changeStepTo(step: SubmitProposalStep): void {
    this.currentStep = step;
    this.determineTitle();
    this.submitProposalStateService.changeStepTo(step);
  }

  backPressed(): void {
    this.changeStepTo(SubmitProposalStep.Choice);
  }

  nextPressed(): void {
    this.changeStepTo(SubmitProposalStep.Confirm);
  }

  isNextButtonDisabled(): boolean {
    return !this.submitProposalStateService.isMinimumPointsReached();
  }

  isSubmitButtonDisabled(): boolean {
    return this.submitProposalStateService.submitDisabled;
  }

  submitPressed(): void {
    this.submitProposalStateService.submitProposal().subscribe((result) => {
      if (result) {
        this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_OK);

        // Reload the submitted plan when submit is successful
        if (this.electives) {
          this.planService.getOrFetchPlanDetailsAndPlanLater(this.electives.planId, true).subscribe();
        }
      }
    });
  }

  private determineTitle(): void {
    this.title =
      this.currentStep === SubmitProposalStep.Confirm
        ? this.translate.instant("submitProposal.confirmTitle")
        : this.translate.instant("submitProposal.title");
  }
}
