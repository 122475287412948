import { Component, inject, Input, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DegreeProgram } from "@app/core/domain/degree-program";
import { ExamType } from "@app/core/domain/exam-type";
import { Role } from "@app/core/domain/user";
import { Constants } from "@core/constants";
import { Draft } from "@core/domain/draft";
import { StudyProgram } from "@core/domain/study-program";
import { EducationService } from "@feature/education/services/education.service";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  ModalController,
} from "@ionic/angular/standalone";
import { TranslatePipe } from "@ngx-translate/core";
import { SearchableData } from "@shared/modals/searchable-select/searchable-data";
import { NgxPermissionsModule } from "ngx-permissions";
import { SearchableSelectComponent } from "../searchable-select/searchable-select.component";

type StartingYear = {
  value: string;
  label: string;
};

@Component({
  selector: "pa-new-draft-modal",
  templateUrl: "./new-draft-modal.component.html",
  styleUrls: ["./new-draft-modal.component.scss"],
  standalone: true,
  imports: [
    FormsModule,
    SearchableSelectComponent,
    NgxPermissionsModule,
    TranslatePipe,
    IonHeader,
    IonToolbar,
    IonButton,
    IonTitle,
    IonContent,
    IonList,
    IonInput,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonText,
    IonFooter,
  ],
})
export class NewDraftModalComponent implements OnInit {
  @Input() title!: string;
  @Input() textOK = "menuGeneral.btnAdd";
  @Input() textCancel = "label.cancel";
  draft = {} as Draft;
  startYears = this.getStartYears();
  examTypes!: ExamType[];
  private allDegreePrograms!: DegreeProgram[];
  private degreeProgramsForExamType!: DegreeProgram[];
  searchableDegreeProgramsForExamType: SearchableData[] = [];
  degreeProgramName? = "";
  studyPrograms: StudyProgram[] = [];
  secondStudyPrograms: StudyProgram[] = [];
  disableStudyProgram = true;
  showErrorIncompatibleStudyPrograms = false;

  protected readonly Role = Role;
  protected readonly Constants = Constants;

  private modalCtrl = inject(ModalController);
  private educationService = inject(EducationService);

  ngOnInit(): void {
    this.fetchExamTypes();
    this.fetchDegreePrograms();
  }

  private getStartYears(): StartingYear[] {
    const nextYear = new Date().getFullYear() + 1;
    const list: StartingYear[] = [];

    for (let i = nextYear; i > nextYear - 6; i--) {
      list.push({ label: `${i} - ${i + 1}`, value: i.toString() });
    }
    return list;
  }

  private fetchExamTypes(): void {
    this.educationService.getExamTypes().subscribe((examTypes) => (this.examTypes = examTypes));
  }

  private fetchDegreePrograms(): void {
    this.allDegreePrograms = [];

    this.educationService.getDegreePrograms().subscribe((degreePrograms) => {
      this.allDegreePrograms = degreePrograms;
    });
  }

  examTypeChanged(): void {
    // the degree program depends on the exam type, so clear the degree program
    // and disable the study program selection (study program depends on degree program)
    this.draft.degreeProgram = "";
    this.degreeProgramName = "";
    this.contextForStudyProgramsChanged();
    this.disableStudyProgram = true;
    // change the list of applicable degree programs
    this.degreeProgramsForExamType = this.allDegreePrograms.filter(
      (degreeProgram) => degreeProgram.examType === this.draft.examType,
    );
    this.searchableDegreeProgramsForExamType = this.degreeProgramsForExamType.map<SearchableData>((degreeProgram) => {
      return { key: degreeProgram.code, value: degreeProgram.name };
    });
  }

  degreeProgramChanged(selectedSearchableDegreeProgram: SearchableData): void {
    this.draft.degreeProgram = selectedSearchableDegreeProgram.key.toString();
    this.disableStudyProgram = false;
    this.degreeProgramName = this.searchableDegreeProgramsForExamType.find(
      (draft) => draft.key === this.draft.degreeProgram,
    )?.value;
    this.contextForStudyProgramsChanged();
  }

  contextForStudyProgramsChanged(): void {
    this.draft.studyProgramCode = "";
    this.draft.secondStudyProgramCode = undefined;
    this.secondStudyPrograms = [];

    if (this.draft.degreeProgram && this.draft.startingYear && this.draft.examType) {
      this.educationService.getStudyPrograms(this.draft).subscribe((studyPrograms) => {
        this.studyPrograms = studyPrograms;
      });
    }
  }

  studyProgramChanged(): void {
    const selectedStudyProgram = this.studyPrograms.find(
      (studyProgram) => this.draft.studyProgramCode === studyProgram.code,
    );
    this.draft.secondStudyProgramCode = undefined;
    this.secondStudyPrograms = [];
    this.showErrorIncompatibleStudyPrograms = false;

    if (selectedStudyProgram?.showSecondStudyProgram) {
      const dummyDraft: Draft = {
        degreeProgram: this.draft.degreeProgram,
        startingYear: this.draft.startingYear,
        examType: "",
      };
      this.educationService.getStudyPrograms(dummyDraft, { bachelorOnly: true }).subscribe((studyPrograms) => {
        this.secondStudyPrograms = studyPrograms;
      });
    }
  }

  secondStudyProgramChanged(): void {
    const selected1stStudyProgram = this.studyPrograms.find(
      (studyProgram) => this.draft.studyProgramCode === studyProgram.code,
    );
    const selected2ndStudyProgram = this.secondStudyPrograms.find(
      (studyProgram) => this.draft.secondStudyProgramCode === studyProgram.code,
    );
    if (!selected1stStudyProgram || !selected2ndStudyProgram) {
      return;
    }

    this.showErrorIncompatibleStudyPrograms =
      selected1stStudyProgram.isChangeExamComponentAllowed !== selected2ndStudyProgram.isChangeExamComponentAllowed;
  }

  showStudentWarning(studyPrograms: StudyProgram[], studyProgramCode?: string): boolean {
    if (studyPrograms && studyProgramCode) {
      return studyPrograms.some(
        (studyProgram) => studyProgramCode === studyProgram.code && !studyProgram.isRegisteredByThisStudent,
      );
    }
    return false;
  }

  isComplete(): boolean {
    return (
      !this.isEmpty(this.draft.name) &&
      this.draft.name!.length <= Constants.MAX_LENGTH_PLAN_NAME &&
      !this.isEmpty(this.draft.startingYear) &&
      !this.isEmpty(this.draft.examType) &&
      !this.isEmpty(this.draft.degreeProgram) &&
      !this.isEmpty(this.draft.studyProgramCode) &&
      (!this.isEmpty(this.draft.secondStudyProgramCode) ||
        (this.draft.secondStudyProgramCode === undefined && this.secondStudyPrograms.length === 0)) &&
      !this.showErrorIncompatibleStudyPrograms
    );
  }

  private isEmpty(value: string | number | undefined | null): boolean {
    return value === null || value === undefined || (typeof value === "string" && value.trim() === "");
  }

  confirm(): void {
    this.modalCtrl.dismiss(this.draft, Constants.BUTTON_ACTION_OK);
  }

  dismiss(): Promise<boolean> {
    return this.modalCtrl.dismiss(null, Constants.BUTTON_ACTION_CANCEL);
  }
}
